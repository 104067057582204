import React from "react";
import { Typography } from "@mui/material";
import { ErrorMessage } from "formik";
const CustomErrorMessage = ({ name }) => {
  return (
    <Typography
      variant="smallestTextPrimary"
      sx={{
        mt: "0.3rem",
        color: "#FC4949",
      }}
    >
      <ErrorMessage name={name} />
    </Typography>
  );
};

export default CustomErrorMessage;
