import { Box, Typography } from "@mui/material";
import { CustomButton } from "components/styledComponents/common";
import { useState } from "react";

import AirSensor from "../../assets/hardwares/airSensor.svg";
import TempHumidity from "../../assets/hardwares/tempHumiditySensor.svg";
import RainSensor from "../../assets/hardwares/rainSensor.svg";
import WindSensor from "../../assets/hardwares/windSensor.svg";
import SoilHumiditySensor from "../../assets/hardwares/soilHumiditySensor.svg";
import SoilTempSensor from "../../assets/hardwares/soilTempSensor.svg";
import UvSensor from "../../assets/hardwares/uvSensor.svg";
import FruitDiameterSensor from "../../assets/hardwares/fruitDiameterSensor.svg";
import StemDiameterSensor from "../../assets/hardwares/stemDiameterSensor.svg";
import LeafHumiditySensor from "../../assets/hardwares/leafHumiditySensor.svg";
import SensorDevice from "../../assets/hardwares/sensorDevice.svg";
import WeatherStation from "../../assets/hardwares/weatherStation.svg";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "States/shopping";
import AirPre from "../../assets/Sensors/Air/pre.png";
import AirTemp from "../../assets/Sensors/Air/TH.png";
import AirHumidity from "../../assets/Sensors/Air/TH.png";
import AirRain from "../../assets/Sensors/Air/baran.png";
import AirWindDir from "../../assets/Sensors/Air/winddirection.png";
import Hesgarino from "../../assets/Sensors/Air/hesgarino.png";
import AirWindSpeed from "../../assets/Sensors/Air/windspeed.png";
import AirLux from "../../assets/Sensors/Air/lux.png";
import SoilHumidity from "../../assets/Sensors/Soil/TH.png";

import PlantRoshd from "../../assets/Sensors/Plant/roshd.png";
import PlantHumidity from "../../assets/Sensors/Plant/H.png";
const details = [
  {
    title: "سنسور فشار هوا",
    type: "sensor",
    description:
      "سنسور فشار هوا با دقت بالا و پاسخ سریع برای اندازه‌گیری دقیق فشار محیط",
    image: AirPre,
  },
  {
    title: "سنسور دمای هوا",
    type: "sensor",
    description:
      "سنسور دمای هوا با دقت بالا و قابلیت پاسخ‌دهی سریع، مناسب برای نظارت و کنترل دمای محیط در کاربردهای مختلف.",
    image: AirTemp,
  },
  {
    title: "سنسور رطوبت هوا",
    type: "sensor",
    description:
      "سنسور رطوبت هوا با دقت بالا و پایداری طولانی‌مدت، ایده‌آل برای اندازه‌گیری و کنترل رطوبت در محیط‌های مختلف",
    image: AirHumidity,
  },
  {
    title: "سنسور باران سنج",
    type: "sensor",
    description:
      "سنسور باران‌سنج با دقت و حساسیت بالا، ایده‌آل برای اندازه‌گیری میزان بارش و پایش شرایط آب و هوایی در کاربردهای کشاورزی و محیط‌زیستی",
    image: AirRain,
  },
  {
    title: "سنسور جهت باد",
    type: "sensor",
    description:
      "سنسور جهت باد با دقت فوق‌العاده و طراحی مقاوم در برابر شرایط جوی، ایده‌آل برای تحلیل دقیق الگوهای باد",
    image: AirWindDir,
  },
  {
    title: "سنسور سرعت باد",
    type: "sensor",
    description:
      "سنسور سرعت باد با دقت بالا و واکنش سریع، طراحی شده برای اندازه‌گیری دقیق و قابل اعتماد سرعت باد در شرایط جوی مختلف و کاربردهای متنوع",
    image: AirWindSpeed,
  },
  {
    title: "سنسور رطوبت خاک",
    type: "sensor",
    description:
      "سنسور رطوبت خاک با دقت بالا و قابلیت اطمینان، ایده‌آل برای نظارت بر رطوبت خاک و بهینه‌سازی آبیاری در کشاورزی و باغبانی",
    image: SoilHumidity,
  },
  {
    title: "سنسور دمای خاک",
    type: "sensor",
    description:
      "سنسور دمای خاک با دقت بالا و قابلیت پایداری، مناسب برای اندازه‌گیری دقیق دمای خاک در سیستم‌های کشاورزی و محیط‌زیستی",
    image: SoilHumidity,
  },
  {
    title: "سنسور بارندگی",
    type: "sensor",
    description:
      "سنسور بارش با دقت بالا و حساسیت قابل تنظیم، طراحی شده برای اندازه‌گیری دقیق میزان بارش در مناطق مختلف",
    image: AirRain,
  },
  {
    title: "سنسور تابش",
    type: "sensor",
    description:
      "سنسور تابش با دقت بالا و قابلیت اندازه‌گیری دقیق در محدوده‌های مختلف، برای ارزیابی دقیق تابش نوری در کاربردهای کشاورزی و بهینه‌سازی رشد گیاهان",
    image: AirLux,
  },
  {
    title: "سنسور نمودار رشد گیاه",
    type: "sensor",
    description:
      "سنسور نمودار رشد گیاه، با قابلیت ثبت و نمایش دقیق فرآیند رشد گیاهان در طول زمان، مناسب برای مطالعات علمی و کاربردهای کشاورزی",
    image: PlantRoshd,
  },
  {
    title: "سنسور قطر میوه",
    type: "sensor",
    description:
      "سنسور قطر میوه با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای ارزیابی ابعاد و کیفیت میوه‌ها در صنایع کشاورزی و فرآوری غذایی",
    image: PlantRoshd,
  },
  {
    title: "سنسور قطر ساقه",
    type: "sensor",
    description:
      "سنسور قطر ساقه با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای مطالعه و اندازه‌گیری قطر و رشد ساقه‌های گیاهان",
    image: StemDiameterSensor,
  },
  {
    title: "سنسور رطوبت برگ",
    type: "sensor",
    description:
      "سنسور رطوبت برگ با دقت بالا و قابلیت اندازه‌گیری دقیق، مناسب برای نظارت و کنترل رطوبت برگ‌ها در کشت‌های گلخانه‌ای و کاربردهای تحقیقاتی در زمینه کشاورزی و باغبانی",
    image: PlantHumidity,
  },
  {
    title: "دستگاه سنسور هوا",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "دستگاه سنسور خاک",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "دستگاه سنسور گیاه",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: SensorDevice,
  },
  {
    title: "ایستگاه هواشناسی",
    type: "sensor",
    description:
      "حسگرهای دقیق و پیشرفته برای اندازه‌گیری بارندگی و ارسال خودکار داده های محیطی به سرور های شما برای نظارت در زمان واقعی.",
    image: WeatherStation,
  },
  {
    title: "حسگرینو",
    type: "sensor",
    description: "حسگری دقیق برای اندازه‌گیری دما، رطوبت و کنترل شرایط محیطی.",
    image: Hesgarino,
  },
];

const DeviceItem = ({ item, setDeviceType }) => {
  console.log("🚀 ~ DeviceItem ~ item:", item.title);

  const getDetail = (item) => {
    const hardware = details.filter((i) => i.title === item.title);
    console.log("🚀 ~ getDetail ~ hardware:", hardware[0]);
    return hardware[0];
  };
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const carts = useSelector((state) => state.shopping.cart);
  const exists = carts.some((i) => i.id === item.id);

  const handleAddToCart = () => {
    if (!exists) dispatch(setCart({ cart: [...carts, item] }));
  };
  return (
    <Box
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      sx={{
        width: "80%",
        height: "196px",
        borderRadius: "8px",
        py: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          borderRadius: "8px",
          position: "absolute",
          height: !active ? 0 : "100%",
          width: "100%",
          transition: "all 0.6s",
          bottom: 0,
          bgcolor: "others.sixth",
          backdropFilter: "blur(7px)",
          px: "1rem",
          py: !active ? 0 : "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
        }}
      >
        <Typography
          sx={{
            opacity: !active ? 0 : 1,
            transition: "opacity 0.6s",
            textAlign: "justify",
          }}
          variant="smallestTextSecondary"
        >
          {getDetail(item)?.description}
        </Typography>

        <Typography
          sx={{ opacity: !active ? 0 : 1, transition: "opacity 0.6s" }}
          variant="smallestTextSecondary"
        >
          {/* لطفا تماس بگیرید */}
          {(item.price / 10).toLocaleString()} تومان
        </Typography>
        {exists ? (
          <CustomButton
            secondary
            onClick={() => handleAddToCart(item)}
            height="32px"
          >
            {exists ? "اضافه شد" : "افزودن"}
          </CustomButton>
        ) : (
          <CustomButton onClick={() => handleAddToCart(item)} height="32px">
            {exists ? "اضافه شد" : "افزودن"}
          </CustomButton>
        )}
      </Box>
      <Box
        sx={{
          borderRadius: "8px",
          padding: "6px",
          bgcolor: "background.third",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mx: "1rem",
        }}
      >
        <Box
          component="img"
          src={getDetail(item)?.image}
          width="56px"
          height="56px"
          sx={{
            objectFit: "contain",
          }}
        />
      </Box>
      <Typography mx="1rem" variant="h4">
        {item.title}
      </Typography>
      <Box sx={{ mx: "1rem", width: "calc(100% - 2rem)", height: "38px" }}>
        <Box
          component="p"
          sx={{
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "2",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          color="fontColor.alt"
        >
          {item.description}
        </Box>
      </Box>
    </Box>
  );
};

export default DeviceItem;
