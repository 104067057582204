import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import Pic1 from "assets/experts/5.png";
import Pic2 from "assets/experts/2.png";
import Pic3 from "assets/experts/4.png";

const initialState = {
  messenger: [
    {
      id: 389,
      name: "4Farm",
      imageUrl: "https://s28.picofile.com/file/8464512900/5.jpg",
      type: "system",
      description: "راهنمای جامع",
      lastTime: "7:21 PM",
      messages: [
        {
          id: uuidv4(),
          me: true,
          text: "  سلام چطوری میتونم از امکانات سیستم استفاده کنم؟ ",
          date: "1402/02/01",
          time: "7:18 PM",
          seen: true,
        },
        {
          id: uuidv4(),
          me: true,
          text: "برای گرفتن اطلاعات بیشتر چه کاری میتونم انجام بدم . من خیلی مشتاقم که از این نرم افزار استفاده کنم. اما طرز کارش رو بلد نیستم. ممکنه راهنمایی بفرمایین؟",
          date: "1402/02/01",
          time: "7:19 PM",
          seen: true,
        },
        {
          id: uuidv4(),
          me: false,
          text: "با عرض سلام و وقت بخیر . برای دریافت اطلاعات در مورد نحوه استفاده سیستم میتوانید به بخش راهنما و سپس به بخش کیف پول مراجعه نمایید.",
          date: "1402/02/01",
          time: "7:20 PM",
          seen: true,
        },
        {
          id: uuidv4(),
          me: true,
          text: "خیلی ممنونم",
          date: "1402/02/01",
          time: "7:21 PM",
          seen: false,
        },
      ],
    },
  ],
  neighbor: [
    {
      id: uuidv4(),
      name: "آقای احمدی",
      type: "neighbor",
      imageUrl: Pic1,
      title: "باغ سیب",
      position: [35.64318, 52.22766],
      lastTime: "8:55 PM",
      messages: [
        {
          id: uuidv4(),
          me: true,
          text: "سلام آقای احمدی. روزتون بخیر",
          date: "1402/02/01",
          time: "7:18 PM",
          seen: true,
        },
        {
          id: uuidv4(),
          me: true,
          text: "شما از چه عملگر هایی در زمینتون استفاده میکنید؟.",
          date: "1402/02/01",
          time: "7:19 PM",
          seen: true,
        },
        {
          id: uuidv4(),
          me: false,
          text: "سلام وقت شما هم بخیر",
          date: "1402/02/01",
          time: "7:20 PM",
          seen: true,
        },
        {
          id: uuidv4(),
          me: false,
          text: "عملگر های مه پاش و پمپ آب.",
          date: "1402/02/01",
          time: "8:55 PM",
          seen: true,
        },
        {
          id: uuidv4(),
          me: true,
          text: "ممنون.",
          date: "1402/02/01",
          time: "7:18 PM",
          seen: true,
        },
      ],
    },
    {
      id: uuidv4(),
      name: "آقای اکبری",
      type: "neighbor",
      imageUrl: Pic2,
      title: "باغ گردو",
      position: [35.64354, 52.21893],
      lastTime: "8:55 PM",
      messages: [],
    },
    {
      id: uuidv4(),
      name: "آقای مصطفوی",
      type: "neighbor",
      imageUrl: Pic3,
      title: "باغ گیلاس",
      position: [35.63597, 52.21736],
      lastTime: "8:55 PM",
      messages: [],
    },
  ],
  selectedMember: null,
  messages: [],
};

export const messengerSlice = createSlice({
  name: "messenger",
  initialState,
  reducers: {
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload.selectedMember;
    },
    setMessenger: (state, action) => {
      state.messenger = action.payload.messenger;
    },

    setMessages: (state, action) => {
      state.messages = action.payload.messages;
    },
  },
});

export const { setMessenger, setSelectedMember, setMessages } =
  messengerSlice.actions;
export default messengerSlice.reducer;
