import { CloseOutlined, Done } from "@mui/icons-material";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { CustomButton } from "components/styledComponents/common";
import { MOBILE_WIDTH } from "constants/constant";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { pureFinalPropsSelectorFactory } from "react-redux/es/connect/selectorFactory";
import { setCart } from "States/shopping";
import { convertToToman } from "Utils/Utils";

const PlanItem = ({ exist, title }) => {
  return (
    <Box display="flex" alignItems="center" gap="0.5rem">
      {exist ? (
        <Done
          sx={{
            color: "others.third",
            width: "15px",
            height: "15px",
          }}
        />
      ) : (
        <CloseOutlined
          sx={{
            color: "others.second",
            width: "15px",
            height: "15px",
          }}
        />
      )}
      <Typography variant="subHeading" color={"fontColor.main"}>
        {title}
      </Typography>
    </Box>
  );
};
const Plan = ({ plan }) => {
  const { t } = useTranslation();
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);
  const lang = useSelector((state) => state.global.language);
  const getPeriod = (str, lang) => {
    switch (str) {
      case "YEAR":
        return lang === "Fa" ? "ساله  " : "  Year";

      default:
        break;
    }
  };
  const dispatch = useDispatch();
  const carts = useSelector((state) => state.shopping.cart);
  console.log("🚀 ~ Plan ~ carts:", carts);
  const exists = carts?.some((item) => item.id === plan.id);

  const handleremoveFromCart = () => {
    const filteredCart = carts.filter((item) => item.id !== plan.id);
    dispatch(setCart({ cart: filteredCart }));
  };

  const handleAddToCart = () => {
    console.log("🚀 ~ Plan ~ plan=========>", plan);

    if (!exists) dispatch(setCart({ cart: [...carts, plan] }));
  };
  return (
    <Box
      p="1.313rem 0.813rem"
      width={isMobileScreens ? "100%" : "20%"}
      height="544px"
      mt="16px"
      borderRadius="8px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        background:
          plan.title === "بسته اقتصادی 5"
            ? ({ palette: { mode } }) =>
                mode === "dark"
                  ? "linear-gradient(193.94deg, #D4AF37 0%, rgba(212, 175, 55, 0) 100%)"
                  : "white linear-gradient(193.94deg, rgba(234, 178, 20, 0.35) 20.25%, #F7F7F7 118%)"
            : ({ palette: { mode } }) =>
                mode === "dark"
                  ? "linear-gradient(193.94deg, #2A80F4 0%, rgba(42, 128, 244, 0) 100%)"
                  : "white linear-gradient(191deg, rgba(42, 128, 244, 0.35) 20.25%, #F7F7F7 118%)",
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          gap="0.25rem"
        >
          <Typography variant="h4" color={"fontColor.main"}>
            {plan.title === "بسته اقتصادی 5" ? "بسته کامل" : plan.title}
          </Typography>
          <Typography variant="subHeading" color={"fontColor.main"}>
            ({plan.timeValue} {getPeriod(plan.timeUnit, lang)})
          </Typography>
        </Box>
        {plan.active && (
          <Box
            width="60px"
            height="23px"
            borderRadius="4px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: "others.third" }}
          >
            <Typography
              variant="smallestTextPrimary"
              color={"background.second"}
            >
              {t("active")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box display="flex" gap="1rem" alignItems="flex-end">
        <Typography variant="h1" color={"fontColor.main"} lineHeight="2rem">
          {convertToToman(plan.price) / 1000000}
        </Typography>
        <Typography variant="body" color={"fontColor.main"}>
          {/* لطفا تماس بگیرید */}
          {t("currency")}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        {plan.services.map((service, index) => (
          <PlanItem exist={true} title={service} />
        ))}
        {plan.notAvailable.map((notAvailable, index) => (
          <PlanItem exist={false} title={notAvailable} />
        ))}
      </Box>
      {/* <Box display="flex" flexDirection="column">
        <PlanItem exist={plan.sensorManagement} title="مدیریت سنسور ها" />
        <PlanItem exist={plan.smartIndex} title="شاخص هوشمند" />
        <PlanItem exist={plan.weather} title="هواشناسی" />
        <PlanItem exist={plan.staffManagement} title="مدیریت کارکنان" />
        <PlanItem exist={plan.growthStage} title="مراحل رشد گیاه" />
        <PlanItem exist={plan.alarms} title="هشدارها" />
        <PlanItem exist={plan.calStatus} title="وضعیت کشت" />
        <PlanItem exist={plan.remoteSensing} title="سنجش از دور" />
        <PlanItem exist={plan.recommends} title="توصیه ها" />
        <PlanItem exist={plan.actuaturs} title="عملگرها" />
        <PlanItem exist={plan.inventory} title="انبارداری" />
        <PlanItem exist={plan.accountant} title="حسابداری" />
      </Box> */}
      <Box display="flex" gap="10px">
        <CustomButton
          height="32px"
          sx={{
            width: exists ? "0%" : "100%",
            display: !exists ? "flex" : "none",
            ...(plan.title === "بسته اقتصادی 5"
              ? {
                  backgroundColor: "#EAB214",
                  "&:focus": {
                    backgroundColor: "#EAB214",
                  },
                  "&:hover": {
                    backgroundColor: "#EAB214",
                    opacity: 0.9,
                  },
                }
              : {}),
          }}
          onClick={() => handleAddToCart()}
        >
          <Typography variant="subHeading">
            {plan.active ? t("added") : t("addToCart")}
          </Typography>
        </CustomButton>

        {exists && (
          <CustomButton
            secondary
            height="32px"
            sx={{
              width: "100%",
            }}
            onClick={() => handleremoveFromCart()}
          >
            {t("remove")}
          </CustomButton>
        )}
      </Box>
    </Box>
  );
};

export default Plan;
