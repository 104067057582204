import axios from "axios";

// const DEVURL = process.env.REACT_APP_PROD_URL;
const DEVURL = process.env.REACT_APP_DEV_URL;
const PRODURL = process.env.REACT_APP_PROD_URL;
// const PRODURL = process.env.REACT_APP_DEV_URL;

// const url = '${URL}'
export const landData = async (token) => {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land`;
  let headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.get(url, { headers });
  console.log("🚀 ~ file: http.service.js:12 ~ landData ~ response:", response);
  return response.data;
};

export const deviceData = async (token) => {
  try {
    const url = `${
      process.env.NODE_ENV === "production" ? PRODURL : DEVURL
    }/api/v1/device/attributes?sortOrder=DES&sortProperty=createdTime&page=0&pageSize=10`;
    const headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };

    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log(
      "🚀 ~ file: http.service.js:31 ~ deviceData ~ response:",
      response
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching sensor data: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching sensor data:", error);
    throw error;
  }
};

export async function lastDataSensor(token, selected) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/plugins/telemetry/DEVICE/${selected}/values/timeseries`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  if (selected)
    try {
      const response = await axios.get(url, { headers });
      console.log(
        "🚀 ~ file: http.service.js:55 ~ lastDataSensor ~ response:",
        response
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
}

export async function currentWeather(token, lon, lat) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/weather/current?lat=${lat}&lon=${lon}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function fetchFiveDayWeather(token, lon, lat) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/weather/five-day?lat=${lat}&lon=${lon}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function fetchDevicesData(token) {
  const apiUrl = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/device`;
  const params = {
    sortOrder: "DES",
    sortProperty: "createdTime",
    page: 0,
    pageSize: 10,
  };

  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(apiUrl, { params, headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function fetchAllDataSensors(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/device/attributes?sortOrder=DES&sortProperty=createdTime&page=0&pageSize=10`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });

    const arr = [];

    await Promise.all(
      response.data.data.data.map(async (sensor) => {
        console.log(
          "🚀 ~ file: http.service.js:147 ~ response.data.data.data.map ~ sensor:",
          sensor
        );
        const filteredPosition = sensor.attributes.filter(
          (item) => item.key === "position"
        );
        const lastActivityTime = sensor.attributes.filter(
          (item) => item.key === "lastActivityTime"
        );
        const inactivityAlarmTime = sensor.attributes.filter(
          (item) => item.key === "inactivityAlarmTime"
        );
        const lastConnectTime = sensor.attributes.filter(
          (item) => item.key === "lastConnectTime"
        );
        const lastDisconnectTime = sensor.attributes.filter(
          (item) => item.key === "lastDisconnectTime"
        );
        const active = sensor.attributes.filter(
          (item) => item.key === "active"
        );
        const id = sensor.id.id;
        const lastData = await lastDataSensor(token, id);
        console.log(
          "🚀 ~ file: http.service.js:150 ~ response.data.data.data.map ~ lastData:",
          lastData.data
        );
        arr.push({
          id: sensor.id.id,
          name: sensor.name,
          label: sensor.label,
          type: sensor.type,
          // attributes: sensor.attributes,
          position: filteredPosition[0].value,
          lastActivityTime: lastActivityTime[0],
          inactivityAlarmTime: inactivityAlarmTime[0],
          lastConnectTime: lastConnectTime[0],
          lastDisconnectTime: lastDisconnectTime[0],
          active: active[0],
          lastData: lastData.data,
        });
      })
    );

    console.log(
      "🚀 ~ file: http.service.js:174 ~ fetchAllDataSensors ~ arr:",
      arr
    );
    return arr;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

function getDaysBetweenEpochDates(epochDate1, epochDate2) {
  // Convert epoch dates to milliseconds
  const millisecondsPerDay = 24 * 60 * 60 * 1000; // 24 hours * 60 minutes * 60 seconds * 1000 milliseconds

  const date1 = new Date(epochDate1 * 1000);
  const date2 = new Date(epochDate2 * 1000);

  // Calculate the difference in milliseconds
  const timeDifference = date2 - date1;

  // Convert the difference to days
  const daysDifference = Math.floor(timeDifference / millisecondsPerDay);

  return daysDifference;
}

export async function fetchTelemetryData(token, start, end, id, type) {
  console.log(
    "🚀 ~ file: http.service.js:194 ~ fetchTelemetryData ~ type:",
    type
  );
  console.log(
    "🚀 ~ file: http.service.js:195 ~ fetchTelemetryData ~ token,start,end,id:",

    start,
    end
  );
  // const diff = calculateDaysBetweenTimestamps(start, end) + 1;
  const diff = getDaysBetweenEpochDates(start, end) + 1;

  console.log(
    "🚀 ~ file: http.service.js:192 ~ fetchTelemetryData ~ diff:",
    diff
  );
  const apiUrl = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v2/plugins/telemetry/DEVICE/${id}/values/timeseries`;
  // api/v2/plugins/telemetry/DEVICE/${id}/values/timeseries?limit={4}&endTs={$end}&startTs={$start}&keys=TA%2CHA
  const paramsAir = {
    limit: diff * 2000,
    endTs: end * 1000,
    startTs: start * 1000,
    keys: "TA,HA",
  };
  const paramsSoil = {
    limit: diff * 2000,
    endTs: end * 1000,
    startTs: start * 1000,
    keys: "HS30,HS60,TS",
  };
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const setParams = () => {
    if (type === "air") return paramsAir;
    else if (type === "soil") {
      return paramsSoil;
    }
  };

  try {
    const response = await axios.get(apiUrl, { params: setParams(), headers });
    console.log(
      "🚀 ~ file: http.service.js:258 ~ fetchTelemetryData ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching telemetry data:", error);
    throw error;
  }
}

export async function fetchAllTelemetryData(token, start, end) {
  console.log("🚀 ~ fetchAllTelemetryData ~ start:", start);
  console.log(
    "🚀 ~ file: http.service.js:252 ~ fetchAllTelemetryData ~ end:",
    end
  );
  console.log(
    "🚀 ~ file: http.service.js:252 ~ fetchAllTelemetryData ~ start:",
    start
  );
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/device/attributes?sortOrder=DES&sortProperty=createdTime&page=0&pageSize=10`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:142 ~ fetchAllDataSensors ~ response:",
      response.data.data.data
    );

    const arr = [];
    // Iterate over the response array and call lastDataSensor for each object

    await Promise.all(
      response.data.data.data.map(async (sensor) => {
        console.log(
          "🚀 ~ file: http.service.js:240 ~ response.data.data.map ~ sensor:",
          sensor
        );
        const filteredPosition = sensor.attributes.filter(
          (item) => item.key === "position"
        );
        const lastActvityTime = sensor.attributes.filter(
          (item) => item.key === "lastActivityTime"
        );
        const inactivityAlarmTime = sensor.attributes.filter(
          (item) => item.key === "inactivityAlarmTime"
        );
        const lastConnectTime = sensor.attributes.filter(
          (item) => item.key === "lastConnectTime"
        );
        const lastDisconnectTime = sensor.attributes.filter(
          (item) => item.key === "lastDisconnectTime"
        );
        const active = sensor.attributes.filter(
          (item) => item.key === "active"
        );
        const id = sensor.id.id;
        const typeForFetch = sensor.attributes.filter(
          (item) => item.key === "type"
        );
        console.log(
          "🚀 ~ response.data.data.data.map ~ typeForFetch:",
          typeForFetch
        );
        const type = typeForFetch[0]?.value;
        console.log("🚀 ~ response.data.data.data.map ~ type:", type);
        const first = new Date(start).getTime() / 1000;
        console.log(
          "🚀 ~ file: http.service.js:312 ~ response.data.data.data.map ~ first:",
          first
        );
        const second = new Date(end).getTime() / 1000;
        console.log(
          "🚀 ~ file: http.service.js:317 ~ response.data.data.data.map ~ second:",
          second
        );
        const lastData = await fetchTelemetryData(
          token,
          first,
          second,
          id,
          type
        );
        console.log(
          "🚀 ~ file: http.service.js:349 ~ response.data.data.data.map ~ lastData:",
          lastData
        );

        if (!sensor.name.includes("gateway")) {
          arr.push({
            id: sensor.id.id,
            name: sensor.name,
            label: sensor.label,
            type: sensor.type,
            attributes: sensor.attributes,
            position: filteredPosition[0].value,
            lastActivityTime: lastActvityTime[0],
            inactivityAlarmTime: inactivityAlarmTime[0],
            lastConnectTime: lastConnectTime[0],
            lastDisconnectTime: lastDisconnectTime[0],
            active: active[0],
            lastData: lastData.data,
          });
        }
      })
    );

    console.log(
      "🚀 ~ file: http.service.js:280 ~ fetchAllTelemetryData ~ arr:",
      arr
    );
    return arr;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getWeatherPeriod(token, startDate, endDate) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/weather/period?startDate=${startDate}&endDate=${endDate}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:299 ~ getWeatherPeriod ~ response:",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getSatelliteImages(
  token,
  startDate,
  endDate,
  visualize = "NDVI"
) {
  console.log(
    "🚀 ~ file: http.service.js:314 ~ getSatelliteImages ~ visualize:",
    visualize
  );
  console.log(
    "🚀 ~ file: http.service.js:312 ~ getSatelliteImages ~ startDate:",
    startDate
  );
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/satelliteImages`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const data = {
    startDate: startDate,
    endDate: endDate,
    visualize: visualize,
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:326 ~ getSatelliteImages ~ response:",
      response
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getLandActive(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/active`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:342 ~ getLandActive ~ response:",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getSensorLastData(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/device/average-last-sensor-data`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function registerNewUser(data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/auth/register`;
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:342 ~ registerUser ~ response:",
      response.data
    );

    // const resEnEmail = await enableEmail(response.data.data.emailConToken);
    // console.log(
    //   "🚀 ~ file: http.service.js:458 ~ registerNewUser ~ resEnEmail:",
    //   resEnEmail
    // );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return error.response.data;
  }
}
export async function sendSms(mobile) {
  console.log("🚀 ~ file: http.service.js:502 ~ sendSms ~ mobile:", mobile);
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/auth/send-mobile-verification`;
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, { mobile: mobile }, { headers });
    console.log(
      "🚀 ~ file: http.service.js:509 ~ sendSms ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function enableMobileAndLogin(data) {
  // const url = `${process.env.NODE_ENV === "production" ? PRODURL : DEVURL}/api/v1/auth/register`;
  // const headers = {
  //   accept: "application/json",
  //   "Content-Type": "application/json",
  // };

  try {
    const resEnMob = await enableMobile({
      mobile: data.mobile,
      verificationCode: data.mobileVerCode,
    });
    console.log(
      "🚀 ~ file: http.service.js:451 ~ registerNewUser ~ resEnMob:",
      resEnMob
    );
    // const res = await loginUser({
    //   userName: data.mobile,
    //   password: data.password,
    // });
    // console.log("🚀 ~ file: http.service.js:452 ~ registerNewUser ~ res:", res);
    return resEnMob;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function loginUser(data) {
  console.log("🚀 ~ loginUser ~ data:", data);
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/auth/login`;
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ loginUser ~ response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function enableMobile(data) {
  console.log("🚀 ~ file: http.service.js:568 ~ enableMobile ~ data:", data);
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/auth/mobile-verification`;
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ loginUser ~ response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function enableEmail(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/auth/enable-email/${token}`;
  const headers = {
    accept: "application/json",
  };

  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ enableEmailRequest ~ response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function recordNewFarm(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:531 ~ recordNewFarm ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
}

export async function activateLand(token, landId) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/active`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const data = {
    landId: landId,
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ activateLand ~ response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
}

export async function getStaffsData(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/staff?page=0&pageSize=100`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function registerNewStaff(token, data) {
  console.log(
    "🚀 ~ file: http.service.js:608 ~ registerNewStaff ~ data:",
    data
  );
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v2/staff/register`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:342 ~ registerUser ~ response:",
      response.data
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function registerNewRecommend(token, data) {
  console.log(
    "🚀 ~ file: http.service.js:608 ~ registerNewStaff ~ data:",
    data
  );
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/recommend`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:342 ~ registerUser ~ response:",
      response.data
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getRecommendsData(token, number = 100) {
  console.log(
    "🚀 ~ file: http.service.js:660 ~ getRecommendsData ~ number:",
    number
  );
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/recommend?page=0&pageSize=${number}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function registerNewEvent(token, data) {
  console.log(
    "🚀 ~ file: http.service.js:726 ~ registerNewEvent ~ data:",
    data
  );

  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/event`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:342 ~ registerUser ~ response:",
      response.data
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getEventsData(token, number = 100) {
  console.log(
    "🚀 ~ file: http.service.js:660 ~ getRecommendsData ~ number:",
    number
  );
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/event?page=0&pageSize=${number}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getStatusLandName(token, str) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/exists?landName=${str}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:780 ~ getStatusLandName ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function editProfile(token, data) {
  console.log(
    "🚀 ~ file: http.service.js:726 ~ registerNewEvent ~ data:",
    data
  );

  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/profile`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  try {
    const response = await axios.put(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:342 ~ registerUser ~ response:",
      response.data
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function changeGrowthStage(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/growStage`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.put(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:780 ~ getStatusLandName ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function registerNewTask(token, data) {
  console.log(
    "🚀 ~ file: http.service.js:726 ~ registerNewEvent ~ data:",
    data
  );

  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/task`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:342 ~ registerUser ~ response:",
      response.data
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getTasksData(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/task?page=0&pageSize=100`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getPackData(token, pck, type) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/plan?page=0&pageSize=100&isPackage=${pck}&type=${type}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:896 ~ getSoftPackData ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function createTicket(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/ticket`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:975 ~ createTicket ~ response:",
      response
    );
    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}
export async function getTickets(token, str) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/ticket?page=0&pageSize=100`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:994 ~ getTickets ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getDetailsTicket(token, id) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/ticket-message?page=0&pageSize=10&id=${id}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:1015 ~ getDetailsTicket ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function createTicketMessage(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/ticket-message`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:975 ~ createTicket ~ response:",
      response
    );
    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function configSensorsAlarm(token, data) {
  console.log("🚀 ~ configSensorsAlarm ~ data:", data);
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/alarm-rule`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:1056 ~ setSettingSensor ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getConfigSensorAlarm(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/alarm-rule`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:1077 ~ getConfigSensorAlarm ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
export async function getAlarms(token) {
  var currentDate = new Date();

  var current = currentDate.toISOString();
  var oneYearAgo = new Date(currentDate);
  oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
  var oneYearAgo = oneYearAgo.toISOString();
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/alarm?pageSize=100&page=0&startDate=${oneYearAgo}&endDate=${current}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log(
      "🚀 ~ file: http.service.js:1097 ~ getAlarms ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function sendMobileResetPassword(mobile) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/auth/forgot-password`;
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, { mobile }, { headers });
    console.log("🚀 ~ sendMobileResetPassword ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function resetPassword(data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/auth/renew-password`;
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ resetPassword ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}
export async function getNotifications(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/unseen-notifications`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getNotifications ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function sendRequestStaff(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/staff/request`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getRequestsFarmer(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/staff/request?page=0&pageSize=10`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getNotifications ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function acceptRejectStaff(token, data) {
  console.log("🚀 ~ configSensorsAlarm ~ data:", data);
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/staff/request`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.put(url, data, { headers });
    console.log(
      "🚀 ~ file: http.service.js:1056 ~ setSettingSensor ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function InqueryStaff(token, mobile) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/staff-inquiry?mobile=${mobile}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getNotifications ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function deleteStaff(token, id) {
  console.log("🚀 ~ deleteStaff ~ id:", id);
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v2/staff/${id}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.delete(url, { headers });
    console.log("🚀 ~ deleteStaff ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function changeStatusTask(token, id, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/task/${id}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.put(url, data, { headers });
    console.log("🚀 ~ changeStatusTask ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getAllUsers(token, page = 0, size = 10000) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/Users?pageSize=${size}&page=${page}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getNotifications ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function createRoom(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/chat/create-room`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ createRoom ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function sendDirectMessage(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/chat/send-direct-message`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ sendDirectMessage ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getDirectMessage(
  token,
  chatRoomId,
  pageSize = 10000,
  page = 0
) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/chat/get-direct-messages?page=${page}&pageSize=${pageSize}&chatRoomId=${chatRoomId}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getContacts(token, pageSize = 100, page = 0) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/chat/users?page=${page}&pageSize=${pageSize}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function editUser(token, data) {
  console.log("🚀 ~ editUser ~ data:", data);
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/user`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(url, data, { headers });
    console.log("🚀 ~ editUser ~ response:", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getExperts(token, pageSize = 10000, page = 0) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/experts?page=${page}&pageSize=${pageSize}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}
export async function getRequestsByExpert(token, pageSize = 10000, page = 0) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/experts/request?page=${page}&pageSize=${pageSize}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function createExpertPlan(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/experts/plan`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ createExpertPlan ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function sendRequestForExpert(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/experts/request`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ sendRequestForExpert ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getAcceptedExpert(token, pageSize = 10000, page = 0) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/accepted-experts?page=${page}&pageSize=${pageSize}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getAcceptedExpert ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}
export async function getExpertsForPay(token, pageSize = 10000, page = 0) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/accepted-expert-invoice?${page}&pageSize=${pageSize}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getAcceptedExpert ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function renewCheckoutEperts(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/recreating-accepted-expert-invoice`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ getAcceptedExpert ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}
export async function changeStatusRequestExpert(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/experts/request`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getAllPlanExpert(token, pageSize = 10000, page = 0) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/experts/plan?page=${page}&pageSize=${pageSize}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getAllPlanExpert ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function editPlan(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/experts/plan`;
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  try {
    const response = await axios.put(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function sendPurchaseService(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/service-purchase-request`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ sendPurchaseService ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getPermissions(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/permissions`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getPermissions ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getLandsAdmin(token, phone) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/user-lands?pageSize=10&page=0&mobile=${phone}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ getLandsAdmin ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function createPlan(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/plan`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ createPlan ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getFreePlan(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/plan?page=0&pageSize=100000&isFree=true&isPackage=true&type=SOFTWARE`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getLandsByMobile(token, mobile) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/user-lands?pageSize=10000&page=0&mobile=${mobile}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function addFreeServiceByAdmin(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/add-free-service`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ addFreeServiceByAdmin ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getStatusSensorName(token, deviceName) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/device/exists?deviceName=${deviceName}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function registerDevice(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/device`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ addFreeServiceByAdmin ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function deleteUserByAdmin(token, mobile) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/users/${mobile}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.delete(url, { headers });
    console.log("🚀 ~ createPlan ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function editFarm(token, landId, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/${landId}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.put(url, data, { headers });
    console.log("🚀 ~ createPlan ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function deleteFarm(token, landId) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/${landId}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.delete(url, { headers });
    console.log("🚀 ~ createPlan ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getSmsWeatherCondition(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/weather/weather-config`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ createPlan ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function setSmsWeatherCondition(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/weather/weather-config`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });
    console.log("🚀 ~ createPlan ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getListFarms(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/lands?pageSize=100000000&page=1`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    console.log("🚀 ~ createPlan ~ response:", response);

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getTokenExternal(farmerId, token) {
  // Ensure base URL is correctly set
  const BASE_URL = process.env.NODE_ENV === "production" ? PRODURL : DEVURL;
  if (!BASE_URL) {
    console.error("❌ API Error: BASE_URL is not defined.");
    throw new Error("BASE_URL is missing.");
  }

  // Encode query parameters to prevent URL issues
  const url = `${BASE_URL}/api/v1/external-apis/mobin-green-house-register?farmer_id=${encodeURIComponent(
    farmerId
  )}&token=${encodeURIComponent(token)}`;
  console.log("🚀 ~ getTokenExternal ~ BASE_URL:", BASE_URL);

  console.log("🚀 Full API URL:", url);

  const headers = {
    accept: "application/json",
  };

  try {
    const response = await axios.get(url, { headers });

    console.log("✅ API Response:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "❌ API Error:",
      error.response?.status,
      error.response?.data || error.message
    );
    throw error;
  }
}

export async function externalPayment(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/service-purchase-request`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });

    console.log("🚀 ~ externalPayment ~ response:", response);
    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function addHesgarino(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/sensorino`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });

    console.log("🚀 ~ externalPayment ~ response:", response);
    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getSensorinoBasedOnLandId(token, landId) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/sensorinos?pageSize=100&page=0&landId=${landId}`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function addDataHesgarino(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/admin/sensorino-data`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function verifyMghPayment(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/users/verify-mgh-payment-request`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });

    return response.data;
  } catch (error) {
    console.error("Error creating ticket:", error);
    throw error;
  }
}

export async function getAllSensorinos(token) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/sensorinos?pageSize=1000&page=0`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getSensorinoData(token, id) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/sensorino-data?pageSize=1000&page=0&sensorinoId=${id}`;
  const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function getCultivStatusPixel(token, data) {
  const url = `${
    process.env.NODE_ENV === "production" ? PRODURL : DEVURL
  }/api/v1/land/each-image-pixel-color`;

  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  try {
    const response = await axios.post(url, data, { headers });

    console.log("🚀 ~ getCultivStatusPixel ~ response:", response)
    return response.data;
  } catch (error) {
    console.log("🚀 ~ getCultivStatusPixel ~ error:", error)
    throw error;
  }
}
