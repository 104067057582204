import { Box, useTheme } from "@mui/material";
import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { getDateShamsiAirSensors } from "Utils/Utils";
Chart.register(...registerables);

const ChartSensorsManagement = ({ dataChart }) => {
  console.log(
    "🚀 ~ file: ChartSensorsManagement.jsx:8 ~ ChartSensorsManagement ~ dataChart:",
    dataChart
  );

  const dailyData = {};

  dataChart?.forEach((item) => {
    const timestamp = item.ts;
    const date = new Date(timestamp);
    const dayKey = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    if (!dailyData[dayKey]) {
      dailyData[dayKey] = { sum: 0, count: 0 };
    }

    dailyData[dayKey].sum += parseFloat(item.value); // Assuming "value" is a string that needs to be converted to a number
    dailyData[dayKey].count += 1;
  });
  console.log("🚀 ~ file: LineNormal.jsx:108 ~ dailyData:", dailyData);

  // Calculate the mean for each day
  const dailyMeans = {};

  for (const dayKey in dailyData) {
    const { sum, count } = dailyData[dayKey];
    dailyMeans[dayKey] = sum / count;
  }
  console.log("🚀 ~ file: LineNormal.jsx:127 ~ dailyMeans:", dailyMeans);

  const label = Object.keys(dailyMeans).reverse(); // Extract and reverse the keys
  console.log("🚀 ~ file: LineNormal.jsx:135 ~ label:", label);
  const shamsiLabel = label?.map((item) => getDateShamsiAirSensors(item));
  const dataSensorChart = label?.map((label) => dailyMeans[label]);

  const theme = useTheme();
  const data = {
    labels: shamsiLabel,
    datasets: [
      {
        lineTension: 0.2,
        label: "First dataset",
        data: dataSensorChart,

        backgroundColor: "rgba(255,116,16,0.1)",
        borderColor: theme.palette.others.first,
      },
    ],
  };
  return (
    <Box
      sx={{
        width: "11.125rem",
        height: "5.188rem",
      }}
    >
      <Line
        data={data}
        options={{
          elements: {
            point: {
              radius: 0,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          maintainAspectRatio: false,
          scales: {
            y: {
              title: {
                display: false,
                text: "",
              },

              grid: {
                // display: false,
                lineWidth: ({ tick }) => (tick.value === 0 ? 2 : 0),
              },
              ticks: {
                display: false,
              },
            },
            x: {
              title: {
                display: false,
                text: "",
              },
              grid: {
                lineWidth: ({ tick }) => (tick.value === 0 ? 0 : 0),
              },
              ticks: {
                display: false,
              },
            },
            yAxes: [
              {
                gridLines: {},
              },
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],

            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
              {
                gridLines: {
                  drawBorder: false,
                },
              },
            ],
          },
        }}
      />
    </Box>
  );
};

export default ChartSensorsManagement;
