import React from "react";
import L from "leaflet";
import { MapContainer, Marker, Polygon, TileLayer } from "react-leaflet";
import Skelton from "components/Skelton/Skelton";
import { MapBox } from "./SensorinoManagement";
import { getIcon } from "scenes/SensorsManagementAll/GetIcon";
import GG1Icon1 from "../../assets/Sensor Icon/G-G 1.png";
import GG2Icon1 from "../../assets/Sensor Icon/G-G 2.png";

export const SensorinoMap = ({
  mode,
  simplifiedA,
  sensors,
  selectedIcon,
  setselectedIcon,
  height,
}) => {
  var GG1Icon = L.icon({
    iconUrl: GG1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
    className: "marker-transition",
  });
  var GG2Icon = L.icon({
    iconUrl: GG2Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
    className: "marker-transition",
  });
  return simplifiedA ? (
    <MapBox mode={mode}>
      <MapContainer
        bounds={simplifiedA}
        zoom={15}
        style={{
          borderRadius: "8px",
          height: height ? height : "570px",
          width: "100%",
        }}
      >
        {/* <ChangeView center={position} zoom={15} /> */}
        <Polygon
          pathOptions={{
            color: "yellow",
            stroke: true,
            fillOpacity: 0,
          }}
          positions={simplifiedA}
        />
        <TileLayer
          attribution="PTR"
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          maxZoom={22}
          maxNativeZoom={18}
        />
        {sensors?.map((item, index) => {
          return (
            <Marker
              eventHandlers={{
                click: (e) => {
                  setselectedIcon(item.id);
                  // setAnimationKey(animationKey + 1);
                  // setCards(item.id);
                },
              }}
              key={index}
              position={[item.latitude, item.longitude]}
              icon={selectedIcon === item.id ? GG2Icon : GG1Icon}
            ></Marker>
          );
        })}
      </MapContainer>
    </MapBox>
  ) : (
    <Skelton />
  );
};
