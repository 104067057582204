import {
  Box,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { memo } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import FlexBetween from "components/FlexBetween/FlexBetween";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { useSelector } from "react-redux";

import ChartZoom from "chartjs-plugin-zoom";
import LineNormal from "components/Charts/LineNormal";
import LineFillupY from "components/Charts/LineFillupY";
import { useEffect } from "react";
import SelectDevice from "./SelectDevice";
import SelectSensor from "./SelectSensor";
import { MOBILE_WIDTH } from "constants/constant";
Chart.register(...registerables);
Chart.register(annotationPlugin);
// --------------------------

// -----------------------
const MixedChart = ({ data, index }) => {
  const theme = useTheme();

  function filterLastDataByLabel(array) {
    return array.map((item) => {
      if (item.label === "air") {
        return {
          ...item,
          lastData: {
            TA: item.lastData.TA || [],
            HA: item.lastData.HA || [],
          },
        };
      } else if (item.label === "soil") {
        return {
          ...item,
          lastData: {
            TS: item.lastData.TS || [],
            HS30: item.lastData.HS30 || [],
            HS60: item.lastData.HS60 || [],
          },
        };
      }
      return item; // Return the item unmodified if the label is neither "air" nor "soil"
    });
  }
  const dataFiltered = filterLastDataByLabel(data);
  console.log("🚀 ~ MixedChart ~ dataFiltered:", dataFiltered)
  console.log("🚀 ~ file: MixedChart.jsx:31 ~ MixedChart ~ data:", data);
  const devices = dataFiltered?.map((item) => item.name);
  const [selectedDevice, setSelectedDevice] = useState(devices[0]);

  const s = dataFiltered?.filter((item) => item.name === selectedDevice);
  console.log("🚀 ~ file: MixedChart.jsx:36 ~ MixedChart ~ s:", s);
  const { ToA, SC, ...filteredData } = s[0].lastData;
  const temp = Object.keys(filteredData);
  console.log("🚀 ~ MixedChart ~ temp:", temp);
  const [sensors, setSensors] = useState(temp);
  console.log("🚀 ~ file: MixedChart.jsx:43 ~ MixedChart ~ sensors:", sensors);
  const [selectedSensor, setSelectedSensor] = useState(sensors[0]);
  console.log(
    "🚀 ~ file: MixedChart.jsx:39 ~ MixedChart ~ selectedSensor:",
    selectedSensor
  );
  useEffect(() => {
    setSensors(Object.keys(s[0].lastData));
    setSelectedSensor(sensors[0]);
  }, [selectedDevice]);
  function getSensorAttributeValues(type, sensor, data) {
    const item = data.find((item) => {
      return item.name === type;
    });
    console.log(
      "🚀 ~ file: MixedChart.jsx:46 ~ getSensorAttributeValues ~ item:",
      item
    );

    if (!item || !item.lastData || !item.lastData[sensor]) {
      return [];
    }

    const convertedData = Object.keys(item.lastData).map((key) => ({
      key,
      data: item.lastData[key].map((item) => ({
        ts: item.ts,
        value: item.value,
      })),
    }));
    const dataChart = convertedData.filter((item) => item.key === sensor);
    console.log(
      "🚀 ~ file: MixedChart.jsx:65 ~ getSensorAttributeValues ~ dataChart:",
      dataChart[0]
    );
    return dataChart;
  }

  // Example usage:

  const attributeValues = getSensorAttributeValues(
    selectedDevice,
    selectedSensor,
    dataFiltered
  );
  console.log(
    "🚀 ~ file: MixedChart.jsx:58 ~ MixedChart ~ attributeValues:",
    attributeValues
  );

  // const fillteredData = s[0].lastData;
  // console.log(
  //   "🚀 ~ file: MixedChart.jsx:41 ~ MixedChart ~ fillteredData:",
  //   fillteredData
  // );
  useEffect(() => {}, [selectedDevice, sensors, s, selectedSensor]);
  const getGradient = (ctx, chartArea, scales) => {
    const gradientBg = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradientBg.addColorStop(0, theme.palette.others.first);
    gradientBg.addColorStop(1, theme.palette.others.second);
    return gradientBg;
  };

  const [flag, setFlag] = useState(true);

  const handleChange = (event) => {
    console.log(
      "🚀 ~ file: MixedChart.jsx:131 ~ handleChange ~ event.target.value:",
      event.target.value
    );
    setSelectedDevice(event.target.value);
  };

  const handleSensorChange = (event) => {
    console.log(
      "🚀 ~ file: MixedChart.jsx:139 ~ handleSensorChange1 ~ event.target.value:",
      event.target.value
    );
    setSelectedSensor(event.target.value);
  };

  const convertName = (item) => {
    switch (item) {
      case "precipitation_rain":
        return "بارندگی";
      case "TA":
        return "دما";
      case "HA":
        return "رطوبت";
      case "TS":
        return "دما خاک";
      case "HS30":
        return "رطوبت خاک 30 متر";
      case "HS60":
        return "رطوبت خاک 60 متر";
      case "shabnam":
        return "نقطه شبنم";
      case "fruit_diameter":
        return "قطر میوه";
      case "stem_diameter":
        return "قطر ساقه";
      case "leaf_humidity":
        return "رطوبت برگ";
      default:
        return "";
    }
  };
  const getUnit = (item) => {
    switch (item) {
      case "precipitation_rain":
        return "میلی متر";
      case "TA":
        return "سانتی گراد";
      case "HA":
        return "درصد";
      case "TS":
        return "سانتی گراد";
      case "HS30":
        return "درصد";
      case "HS60":
        return "درصد";
      case "shabnam":
        return "سانتی گراد ";
      case "fruit_diameter":
        return "سانتی متر";
      case "stem_diameter":
        return "سانتی متر";
      case "leaf_humidity":
        return " درصد";
      default:
        return " ";
    }
  };

  const getChart = () => {
    switch (true) {
      case selectedSensor === "HA":
        return (
          <LineNormal
            th2={true}
            sensorData={attributeValues[0]}
            min={0}
            max={100}
            stepSize={50}
            thv1={75}
            thv2={20}
            h={135}
          />
        );
      case selectedSensor === "TA":
        return (
          <LineFillupY
            th2={true}
            sensorData={attributeValues[0]}
            min={-50}
            max={50}
            stepSize={50}
            thv1={25}
            thv2={-25}
            h={135}
          />
        );
      case selectedSensor === "HS" ||
        selectedSensor === "HS30" ||
        selectedSensor === "HS60":
        return (
          <LineNormal
            th2={false}
            sensorData={attributeValues[0]}
            min={0}
            max={100}
            stepSize={50}
            thv1={15}
            h={135}
          />
        );
      default:
        return (
          <LineNormal
            th1={false}
            th2={false}
            sensorData={attributeValues[0]}
            min={0}
            max={100}
            stepSize={50}
            thv1={15}
            h={135}
          />
        );
    }
  };
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);

  return (
    <Box
      mt="0.5rem"
      sx={{
        width: "100%",
        height: "85%",
      }}
    >
      <FlexBetween
        flexDirection={isMobileScreens ? "column" : "row"}
        sx={{
          alignItems: isMobileScreens ? "flex-start" : "center",
          // justifyContent: isMobileScreens ? "flex-end" : "center",
          gap: "0.5rem",
        }}
      >
        <FlexBetween
          width="200px"
          gap="0.5rem"
          sx={{ justifyContent: "flex-start" }}
        >
          <Typography fontSize={theme.typography.smallestTextSecondary}>
            {convertName(selectedSensor)}
          </Typography>
          <Typography fontSize={theme.typography.smallestTextSecondary}>
            {convertName(selectedSensor) ? `(${getUnit(selectedSensor)})` : ""}
          </Typography>
        </FlexBetween>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          <SelectDevice
            setSelectedDevice={setSelectedDevice}
            selectedDevice={selectedDevice}
            data3={devices}
          />
          <SelectSensor
            keys={sensors}
            selectedSensor={selectedSensor}
            setSelectedSensor={setSelectedSensor}
          />
        </Box>
      </FlexBetween>
      {flag ? getChart() : null}
    </Box>
  );
};

export default memo(MixedChart);
