import { Typography, useMediaQuery } from "@mui/material";
import { CustomButton } from "components/styledComponents/common";
import WidgetWrapper from "components/WidgetWrapper";
import { MOBILE_WIDTH } from "constants/constant";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NoPermission = ({ item }) => {
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = useSelector((state) => state.global.language);
  return (
    <WidgetWrapper
      width={isMobileScreens ? "96%" : "98%"}
      height="83vh"
      m={
        isMobileScreens
          ? "0.5rem"
          : lang === "Fa"
          ? "1rem 1rem 0 0rem"
          : "1rem 0rem 0 1rem"
      }
      display="flex"
      flexDirection="column"
      gap="0.5rem"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="subHeading"
        color="fontColor.main"
        textAlign="center"
      >
        {`${t("noPermission1")} ${item} 
        ${t("noPermission2")}`}
      </Typography>
      <CustomButton
        onClick={() => navigate("/shopping")}
        sx={{ textTransform: "none" }}
      >
        {t("shopping")}
      </CustomButton>
    </WidgetWrapper>
  );
};

export default NoPermission;
