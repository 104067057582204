import { GoogleOAuthProvider } from "@react-oauth/google";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";

import { createTheme } from "@mui/material/styles";
import { useEffect, useMemo, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/Layout/Layout";

import Farms from "mob/Farms/Farms";
import { MOBILE_WIDTH } from "constants/constant";
import { Toaster, ToastBar } from "react-hot-toast";
import { getPermissions } from "Services/Http/http.service";
import { setUserPermissions } from "States";
import Loading from "components/Loading/Loading";
import AdminFarmList from "Admin/AdminDashboard/AdminFarmList";
import LoginExternal from "./components/LoginExternal/LoginExternal";
import AdminHesgarino from "Admin/AdminDashboard/AdminHesgarino/AdminHesgarino";
import AdminHesgarinoIndex from "Admin/AdminDashboard/AdminHesgarino/AdminHesgarinoIndex";
import SensorinoManagement from "scenes/Sensorino/SensorinoManagement";

const Login = lazy(() => import("scenes/Login/Login"));
const ResetPassword = lazy(() => import("scenes/ResetPassword/ResetPassword"));
const SensorManagement = lazy(() =>
  import("scenes/SensorManagement/SensorManagement")
);
const AlarmManagementIndex = lazy(() =>
  import("scenes/AlarmsManagement/AlarmManagementIndex")
);
const WeatherIndex = lazy(() => import("scenes/Weather/WeatherIndex"));
const ActuatorIndex = lazy(() => import("scenes/Actuator/ActuatorIndex"));
const RecordFarmIndex = lazy(() => import("scenes/RecordFarm/RecordFarmIndex"));
const RecordEventsIndex = lazy(() =>
  import("scenes/RecordEvents/RecordEventsIndex")
);
const RecordSensorsIndex = lazy(() =>
  import("scenes/RecordSensors/RecordSensorsIndex")
);
const WalletIndex = lazy(() => import("scenes/Wallet/WalletIndex"));
const MessengerIndex = lazy(() => import("scenes/Messenger/MessengerIndex"));
const RemoteSensingIndex = lazy(() =>
  import("scenes/RemoteSensing/RemoteSensingIndex")
);
const RecommendsIndex = lazy(() => import("scenes/Recommends/RecommendsIndex"));
const GrowthStagesIndex = lazy(() =>
  import("scenes/GrowthStages/GrowthStagesIndex")
);
const SmartIndexesIndex = lazy(() =>
  import("scenes/SmartIndexes/SmartIndexesIndex")
);
const StaffMgmtIndex = lazy(() => import("scenes/StaffMgmt/StaffMgmtIndex"));
const StaffMgmtIndividual = lazy(() =>
  import("mob/scenesMob/StaffMgmtIndividual/StaffMgmtIndividual")
);
const VoiceAssistant = lazy(() =>
  import("mob/scenesMob/VoiceAssistant/VoiceAssistant")
);
const SettingIndex = lazy(() => import("scenes/Setting/SettingIndex"));
const ReportsIndex = lazy(() => import("scenes/Reports/ReportsIndex"));
const TicketIndex = lazy(() => import("scenes/Ticket/TicketIndex"));
const HelpIndex = lazy(() => import("scenes/Help/HelpIndex"));
const RecordUserIndex = lazy(() => import("scenes/RecordUser/RecordUserIndex"));
const DashboardIndex = lazy(() => import("scenes/dashboard/DashboardIndex"));
const RecordRecommendIndex = lazy(() =>
  import("scenes/RecordRecommend/RecordRecommendIndex")
);
const RequestExpertIndex = lazy(() =>
  import("scenes/expert/RequestExpertIndex")
);
const FarmsIndex = lazy(() => import("scenes/expert/FarmsIndex"));
const PricingIndex = lazy(() => import("scenes/expert/PricingIndex"));
const NoWidget = lazy(() => import("scenes/NoWidget/NoWidget"));
const ShoppingIndex = lazy(() => import("scenes/Shopping/ShoppingIndex"));
const NeighborIndex = lazy(() => import("scenes/Neighbor/NeighborIndex"));
const TrackingIndex = lazy(() => import("scenes/Tracking/TrackingIndex"));
const ExpertIndex = lazy(() => import("scenes/Experts/ExpertIndex"));
const OtpForm = lazy(() => import("components/Form/OtpForm"));
const Register = lazy(() => import("scenes/Register/Register"));
const AdminPermission = lazy(() =>
  import("Admin/AdminDashboard/AdminPermission")
);
const AdminAddPlan = lazy(() => import("Admin/AdminDashboard/AdminAddPlan"));
const CheckoutPage = lazy(() => import("scenes/Shopping/CheckoutPage"));
const AdminDashboardIndex = lazy(() =>
  import("Admin/AdminDashboard/AdminDashboardIndex")
);
const AdminDashboard = lazy(() =>
  import("Admin/AdminDashboard/AdminDashboard")
);
const RequestFarmer = lazy(() => import("scenes/RequestFarmer/RequestFarmer"));
const StaffViewMob = lazy(() => import("scenes/StaffMgmt/StaffViewMob"));

function App() {
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);
  const mode = useSelector((state) => state.global.mode);
  const language = useSelector((state) => state.global.language);
  const theme = useMemo(
    () => createTheme(themeSettings(mode, language)),
    [mode, language]
  );
  const user = useSelector((state) => state.global.userType);
  console.log("🚀 ~ file: App.js:47 ~ App ~ user:", user);
  const token = useSelector((state) => state.global.token);
  console.log("🚀 ~ file: App.js:49 ~ App ~ token:", token);

  const dispatch = useDispatch();

  useEffect(() => {
    const gettingPermission = async () => {
      const permissionsResult = await getPermissions(token);
      console.log(
        "🚀 ~ gettingPermission ~ permissionsResult======>",
        permissionsResult
      );
      dispatch(
        setUserPermissions({
          userPermissions: permissionsResult.data.groupPermissions,
        })
      );
    };
    if (user !== "ADMIN") {
      gettingPermission();
    }
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <div className="app" dir={language === "Fa" ? "rtl" : "ltr"}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <GoogleOAuthProvider clientId="141838505432-bn0de50o8d1u25dkpp0fsl4h88qqvkh2.apps.googleusercontent.com">
              {/* zeroLineColor: "transparent" */}

              {user !== "" ? (
                <Routes>
                  <Route path="/login" element={<Login />} />

                  <Route path="/register" element={<Register />} />
                  <Route path="/resetPassword" element={<ResetPassword />} />
                  <Route path="/verify" element={<OtpForm />} />
                  <Route path="/voiceAssistant" element={<VoiceAssistant />} />
                  <Route element={<Layout />}>
                    <Route
                      path="/"
                      element={<Navigate to="/management" replace />}
                    />

                    <Route path="/noWidget" element={<NoWidget />} />
                    {user === "ADMIN" && (
                      <Route path="/plan" element={<AdminAddPlan />} />
                    )}
                    {user === "ADMIN" && (
                      <Route path="/permission" element={<AdminPermission />} />
                    )}
                    {user === "ADMIN" && (
                      <Route path="/farmlist" element={<AdminFarmList />} />
                    )}
                    {user === "ADMIN" && (
                      <Route
                        path="/record-sensorino"
                        element={<AdminHesgarinoIndex />}
                      />
                    )}
                    {user === "ADMIN" && (
                      <Route
                        path="/management"
                        element={<AdminDashboardIndex />}
                      />
                    )}
                    {user === "Staff" && (
                      <Route path="/management" element={<StaffViewMob />} />
                    )}
                    {user === "Staff" && (
                      <Route
                        path="/requestsFarmer"
                        element={<RequestFarmer />}
                      />
                    )}
                    {(user === "FARMER" || user === "EXPERT") && (
                      <Route path="/management" element={<DashboardIndex />} />
                    )}
                    {user === "FARMER" && (
                      <Route
                        path="/external/token"
                        element={<LoginExternal />}
                      />
                    )}
                    {(user === "FARMER" || user === "EXPERT") && (
                      <Route
                        path="/remotesensing"
                        element={<RemoteSensingIndex />}
                      />
                    )}
                    {(user === "FARMER" || user === "EXPERT") && (
                      <Route
                        path="/sensormanagement"
                        element={<SensorManagement />}
                      />
                    )}
                    {(user === "FARMER" ||
                      user === "EXPERT" ||
                      user === "Staff") && (
                      <Route path="/weather" element={<WeatherIndex />} />
                    )}
                    {(user === "FARMER" ||
                      user === "EXPERT" ||
                      user === "Staff") && (
                      <Route path="/ticket" element={<TicketIndex />} />
                    )}

                    {user === "FARMER" && (
                      <Route path="/checkout" element={<CheckoutPage />} />
                    )}
                    {/* {(user === "FARMER" || user === "EXPERT") && (
                    <Route path="/report" element={<ReportsIndex />} />
                  )} */}
                    {/* {(user === "FARMER" || user === "EXPERT") && (
                    <Route path="/actuator" element={<ActuatorIndex />} />
                  )}
                  {(user === "FARMER" || user === "EXPERT") && (
                    <Route path="/wallet" element={<WalletIndex />} />
                  )}
                  {(user === "FARMER" || user === "EXPERT") && (
                    <Route path="/shopping" element={<ShoppingIndex />} />
                  )} */}
                    {(user === "FARMER" || user === "EXPERT") && (
                      <Route path="/shopping" element={<ShoppingIndex />} />
                    )}
                    {(user === "FARMER" ||
                      user === "EXPERT" ||
                      user === "Staff") && (
                      <Route path="/messenger" element={<MessengerIndex />} />
                    )}
                    {user === "FARMER" && (
                      <Route path="/experts" element={<ExpertIndex />} />
                    )}
                    {/* {user === "FARMER" && (
                    <Route path="/neighborhood" element={<NeighborIndex />} />
                  )} */}
                    {(user === "FARMER" || user === "EXPERT") && (
                      <Route
                        path="/growthstage"
                        element={<GrowthStagesIndex />}
                      />
                    )}
                    {/* {(user === "FARMER" || user === "EXPERT") && (
                    <Route
                      path="/smartindexes"
                      element={<SmartIndexesIndex />}
                    />
                  )} */}
                    {(user === "FARMER" || user === "EXPERT") && (
                      <Route path="/help" element={<HelpIndex />} />
                    )}
                    {(user === "FARMER" || user === "EXPERT") && (
                      <Route path="/recommends" element={<RecommendsIndex />} />
                    )}

                    {user === "FARMER" && (
                      <Route path="/staffmgmt" element={<StaffMgmtIndex />} />
                    )}
                    {(user === "FARMER" ||
                      user === "Staff" ||
                      user === "EXPERT") && (
                      <Route path="/setting" element={<SettingIndex />} />
                    )}
                    {user === "FARMER" && (
                      <Route path="/recordfarm" element={<RecordFarmIndex />} />
                    )}
                    {user === "FARMER" && (
                      <Route
                        path="/recordEvents"
                        element={<RecordEventsIndex />}
                      />
                    )}
                    {user === "FARMER" && (
                      <Route
                        path="/recordSensors"
                        element={<RecordSensorsIndex />}
                      />
                    )}
                    {user === "FARMER" && (
                      <Route path="/recordUser" element={<RecordUserIndex />} />
                    )}
                    {/* {user === "FARMER" && (
                    <Route
                      path="/recordRecommend"
                      element={<RecordRecommendIndex />}
                    />
                  )} */}
                    {/* {user === "FARMER" && (
                    <Route path="/tracking" element={<TrackingIndex />} />
                  )} */}

                    {user === "FARMER" && (
                      <Route
                        path="/staffmgmt/:email"
                        element={
                          isMobileScreens ? (
                            <StaffMgmtIndividual />
                          ) : (
                            <Navigate to="/staffmgmt" replace />
                          )
                        }
                      />
                    )}
                    {user === "FARMER" && (
                      <Route
                        path="/farms"
                        element={
                          isMobileScreens ? (
                            <Farms />
                          ) : (
                            <Navigate to="/recordfarm" replace />
                          )
                        }
                      />
                    )}

                    <Route path="/alarms" element={<AlarmManagementIndex />} />
                    <Route path="/sensorino" element={<SensorinoManagement />} />

                    {user === "EXPERT" && (
                      <Route
                        path="/recordRecommend"
                        element={<RecordRecommendIndex />}
                      />
                    )}
                    {user === "EXPERT" && (
                      <Route
                        path="/requests"
                        element={<RequestExpertIndex />}
                      />
                    )}
                    {user === "EXPERT" && (
                      <Route path="/farmsList" element={<FarmsIndex />} />
                    )}
                    {user === "EXPERT" && (
                      <Route path="/pricing" element={<PricingIndex />} />
                    )}
                  </Route>
                </Routes>
              ) : (
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/external/token" element={<LoginExternal />} />
                  <Route path="/register" element={<Register />} />
                  <Route path="/verify" element={<OtpForm />} />
                  <Route path="/resetPassword" element={<ResetPassword />} />
                  <Route path="*" element={<Login />} />
                </Routes>
              )}
              <Toaster
                toastOptions={{
                  position: "top-center",
                }}
              >
                {(t) => (
                  <ToastBar
                    toast={t}
                    style={{
                      ...t.style,
                      animation: t.visible
                        ? "custom-enter 1s ease"
                        : "custom-exit 1s ease",
                    }}
                  />
                )}
              </Toaster>
            </GoogleOAuthProvider>
          </ThemeProvider>
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
