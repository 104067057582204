import { Box, InputAdornment } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import { useEffect, useRef, useState } from "react";
import calendar from "assets/calendar.svg";
import calendarLight from "assets/calendar-light.svg";
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { getDateShamsi, getDateShamsiAirSensors } from "Utils/Utils";
import { useSelector } from "react-redux";
import { CssTextField } from "components/styledComponents/common";
const CustomDatePicker = ({
  customWidth,
  date,
  handleChange,
  placeholder,
  ...props
}) => {
  const datePickerRef = useRef();
  const mode = useSelector((state) => state.global.mode);
  const [portalTarget, setPortalTaget] = useState();
  const portalRef = useRef();
  useEffect(() => {
    const portalDiv = portalRef.current;
    document.body.appendChild(portalDiv);

    setPortalTaget(portalDiv);

    return () => portalDiv.remove();
  }, []);
  return (
    <div>
      <Box
        ref={portalRef}
        sx={{
          width: "250px",

          height: "45px",
          background: "transparent",
          zIndex: 1000,
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          gap: "1rem",
          "& > div": {
            zIndex: "401 !important",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ddd",
          },
          "& .rmdp-wrapper": {
            backgroundColor: "background.third",
            borderRadius: "8px",
            overflow: "hidden",
          },
          "& .rmdp-container": {
            width: "100%",
            display: "block",
            fontSize: "12px",
          },
          "& .rmdp-day": {
            color: "fontColor.main",
          },
          "& .rmdp-arrow": {
            borderColor: "fontColor.main",
          },
          "& .rmdp-week-day": {
            color: "fontColor.alt",
          },
          "& .rmdp-header-values span": {
            color: "fontColor.main",
          },
          "& .rmdp-panel-header": {
            color: "fontColor.main",
          },
          "& .rmdp-ep-arrow": {
            borderBottom: "none",
            borderTop: "none",
          },
          "& .ep-arrow::after": {
            backgroundColor: "background.third",
          },
          "& .rmdp-ep-shadow:after": {
            boxShadow: "none",
          },
          "& .rmdp-month-picker": {
            backgroundColor: "background.third",
          },
          "& .rmdp-year-picker": {
            backgroundColor: "background.third",
          },
          "& .rmdp-input": {
            color: "white !important",
            fontFamily: "YekanBakhFaNum-Regular",
          },
          "& .rmdp-top-class": {
            backgroundColor: "background.third",
            color: "#fff",
          },
          "& .rmdp-day.rmdp-today span": {
            backgroundColor: "rgba(42,128,244,0.5)",
          },
          "& .rmdp-day.rmdp-selected span:not(.highlight)": {
            backgroundColor: "primary.alt",
          },
          "& .rmdp-day .sd": {
            color: "fontColor.main",
          },
          "& .rmdp-week .rmdp-week-day": {
            color: "fontColor.alt",
          },
          "& .rmdp-header-values": {
            color: "fontColor.main",
          },
          "& .rmdp-shadow": {
            boxShadow: "none",
          },
          "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
            backgroundColor: "primary.alt",
          },
        }}
      ></Box>

      <DatePicker
        portal
        portalTarget={portalTarget}
        render={
          <div
            onClick={() => datePickerRef.current.openCalendar()}
            style={{
              width: customWidth ? customWidth : "100%",

              cursor: "pointer",
            }}
          >
            <CssTextField
              sx={{ cursor: "pointer" }}
              value={date ? getDateShamsiAirSensors(new Date(date)) : ""}
              label={placeholder}
              InputLabelProps={{ shrink: !!date }}
              // {...props}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={mode === "dark" ? calendar : calendarLight}
                      alt="calendar icon"
                      sx={{
                        width: "16px",
                        height: "16px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        }
        onOpenPickNewDate={false}
        calendarPosition="top-center"
        className="bg-dark rmdp-prime"
        calendar={persian}
        locale={persian_fa}
        weekDays={["ش", "ی", "د", "س", "چ", "پ", "ج"]}
        value={date ? getDateShamsi(new Date(date)) : ""}
        onChange={handleChange}
        ref={datePickerRef}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default CustomDatePicker;
