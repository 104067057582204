import { Box, styled, Typography } from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import FlexBetween from "components/FlexBetween/FlexBetween";

import MixedChart from "components/MixedChart/MixedChart";
import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CopyRight from "widgets/CopyRight";
import GG1Icon1 from "../../assets/Sensor Icon/G-G 1.png";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { useQuery } from "react-query";
import {
  fetchAllDataSensors,
  fetchAllTelemetryData,
  getAllSensorinos,
  getLandActive,
  landData,
} from "Services/Http/http.service";
import { getDateShamsiAirSensors } from "Utils/Utils";
import Skelton from "components/Skelton/Skelton";

import ButtonsSensorsManagementAll from "scenes/SensorsManagementAll/ButtonsSensorsManagementAll";
import SensorCards from "scenes/SensorsManagementAll/SensorCards";
import { MapAllSensors } from "scenes/SensorsManagementAll/MapAllSensors";
import { SensorinoMap } from "./SensorinoMap";
import NoPermission from "components/NoPermission/NoPermission";
import { useTranslation } from "react-i18next";
import SensorinoCards from "./SensorinoCards";

export const MapBox = styled(Box)(({ theme, mode }) => ({
  position: "absolute",
  width: "100% !important",
  // maxheight: "100%",
  padding: "0px",

  maxHeight: "100%",
  "& .leaflet-left": {
    left: "14px",
  },
  "& .leaflet-bar": {
    border: "none",
  },

  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
}));

// const ChangeView = ({ center, zoom }) => {
//   const map = useMap();
//   map.setView(center, zoom);
//   return null;
// };

const SensorinoManagement = () => {
  const [animationKey, setAnimationKey] = useState(0);
  const token = useSelector((state) => state.global.token);
  const [mapModal, setMapModal] = useState(false);
  const [titleDevice, setTitleDevice] = useState("");
  const permissions = useSelector((state) => state.global.userPermissions);
  console.log("🚀 ~ SensorinoManagement ~ permissions:", permissions);

  const [cards, setCards] = useState(null);

  const [position, setPosition] = useState([35.643029, 52.224928]);

  const landActiveQuery = useQuery(["landActiveQuery", token], () => {
    return getLandActive(token);
  });

  const allSensorinosQuery = useQuery(["allSensorinos", token], () => {
    return getAllSensorinos(token);
  });

  const { isLoading, isSuccess, data } = landActiveQuery;
  const {
    isLoading: allSensorinosLoading,
    isSuccess: allSensorinosSuccess,
    data: allSensorinosData,
  } = allSensorinosQuery;
  console.log(
    "🚀 ~ SensorinoManagement ~ allSensorinosData:",
    allSensorinosData?.data?.sensorinos
  );
  console.log("🚀 ~ SensorinoManagement ~ data:", data);
  const simplifiedA = isSuccess
    ? data.data.land.coordinates.map((item) => [item.latitude, item.longitude])
    : [];

  useEffect(() => {}, [position]);
  const [toggle, setToggle] = useState(false);
  const [selectedIcon, setselectedIcon] = useState(null);
  const mode = useSelector((state) => state.global.mode);
  const { t } = useTranslation();
  var GG1Icon = L.icon({
    iconUrl: GG1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
  });

  return (
    isSuccess &&
    simplifiedA &&
    (!permissions.SENSORINO ? (
      <NoPermission item={t("sensorino")} />
    ) : (
      <Box position="relative" width="98%" height="83vh" m="1rem 1rem 1rem 0">
        <FlexBetween
          gap="0.5rem"
          sx={{
            position: "absolute",
            zIndex: "1000",
            top: "15px",
            right: "10px",
          }}
        ></FlexBetween>
        {selectedIcon !== null && (
          <SensorinoCards selectedIcon={selectedIcon} toggle={toggle} />
        )}

        <Box>
          <Box
            width="99.5%"
            height="576px"
            position="relative"
            display="flex"
            flexDirection="column"
          >
            {allSensorinosSuccess && (
              <SensorinoMap
                mode={mode}
                simplifiedA={simplifiedA}
                sensors={allSensorinosData?.data?.sensorinos}
                selectedIcon={selectedIcon}
                setselectedIcon={setselectedIcon}
              />
            )}
          </Box>
        </Box>
        <Box width="92vw">
          <CopyRight />
        </Box>
      </Box>
    ))
  );
};

export default SensorinoManagement;
