import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getPermissions, getTokenExternal } from "Services/Http/http.service";
import { setLogin, setUserPermissions } from "States";

const LoginExternal = () => {
  console.log("*********************************");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  console.log("🚀 ~ LoginExternal ~ location:", location);

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const farmerId = searchParams.get("farmer_id");

  console.log("Parameters ====>", { token, farmerId });

  useEffect(() => {
   
    const fetchToken = async () => {
      // if (!token) {
      //   console.warn("No token provided in URL.");
      //   return;
      // }

      try {
        console.log("🚀 Fetching external token...");
        const response = await getTokenExternal(farmerId, token);
        console.log("✅ API Response:", response);

        const permissionsResult = await getPermissions(response?.data?.token);
        console.log("🚀 ~ fetchToken ~ permissionsResult:", permissionsResult);

        dispatch(
          setLogin({
            userType: "FARMER",
            token: { token: response?.data?.token },
            email: response?.data?.user?.email,
            fullName: response?.data?.user?.fullName,
            mobile: response?.data?.user?.mobile,
            profileImage: response?.data?.user?.profileImage,
          })
        );
        if (permissionsResult.message !== "You do not have any active land.")
          dispatch(
            setUserPermissions({
              userPermissions: permissionsResult.data.groupPermissions,
            })
          );
        navigate("/");
      } catch (error) {
        console.error("❌ Error fetching token:", error);
      }
    };
    fetchToken();
  }, [dispatch, navigate, token, farmerId]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* نمایش یک لودینگ برای تجربه کاربری بهتر */}
      <h3>Processing login...</h3>
    </div>
  );
};

export default LoginExternal;
