import { CacheProvider } from "@emotion/react";
import { Box, MenuItem, Typography } from "@mui/material";
import CustomDatePicker from "components/CustomDatePicker/CustomDatePicker";
import CustomErrorMessage from "components/CustomErrorMessage/CustomErrorMessage";
import {
  cacheRtl,
  CssTextField,
  CustomButton,
} from "components/styledComponents/common";
import WidgetWrapper from "components/WidgetWrapper";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  addFreeServiceByAdmin,
  addHesgarino,
  getFreePlan,
  getLandsByMobile,
} from "Services/Http/http.service";
import { showToast } from "Utils/Utils";

const AdminHesgarino = () => {
  const token = useSelector((state) => state.global.token);
  const lang = useSelector((state) => state.global.language);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      mobile: "",
      landId: "",
      zone: "",
      deviceSerial: "",
      latitude: null,
      longitude: null,
    },
    onSubmit: async (values, { resetForm }) => {
      console.log("🚀 ~ onSubmit: ~ values:", values);
      try {
        const data = {
          landId: values.landId,
          zone: values.zone,
          deviceSerial: values.deviceSerial,
          latitude: parseFloat(values.latitude),
          longitude: parseFloat(values.longitude),
        };
        const response = await addHesgarino(token, data);
        if (response.status === 200) {
          showToast("success", response.message);
          resetForm();
        } else {
          showToast("error", response.message);
        }
        // Handle form submission
      } catch (error) {
        showToast("error", error.message);
      }
    },
  });

  const { mobile } = formik.values;

  const landsQuery = useQuery(
    ["landsQuery", token, mobile],
    () => getLandsByMobile(token, mobile),
    {
      enabled: !!mobile, // Only run this query if mobile is not empty
    }
  );

  useEffect(() => {
    if (mobile) {
      landsQuery.refetch();
    }
  }, [mobile]);

  return (
    <WidgetWrapper width="36vw" height="83vh">
      <Typography variant="h3" color={"fontColor.main"}>
        ثبت حسگرینو
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="80%"
      >
        <FormikProvider value={formik}>
          <CacheProvider value={cacheRtl}>
            <Form onSubmit={formik.handleSubmit}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <Box>
                  <Box
                    display="grid"
                    gap="16px"
                    gridTemplateColumns="repeat(2, 1fr)"
                    sx={{
                      // flexDirection: "column",
                      "& .MuiInputBase-root ": {
                        height: "45px !important",
                      },
                    }}
                  >
                    <Box display="Flex" flexDirection="column" flex={1}>
                      <Field name="mobile">
                        {({ field }) => (
                          <CssTextField
                            {...field}
                            label={"شماره همراه"}
                            variant="outlined"
                            style={{ width: "250px" }}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldValue("mobile", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                      <CustomErrorMessage name="mobile" />
                    </Box>

                    <Box display="Flex" flexDirection="column" flex={1}>
                      <Field name="landId">
                        {({ field }) => (
                          <CssTextField
                            {...field}
                            select
                            label={"نام زمین"}
                            style={{ width: "250px" }}
                          >
                            {landsQuery?.data?.data?.lands[0]?.lands.map(
                              (land) => (
                                <MenuItem value={land?.landId}>
                                  {land?.land?.name}
                                </MenuItem>
                              )
                            )}
                          </CssTextField>
                        )}
                      </Field>
                      <CustomErrorMessage name="landId" />
                    </Box>
                    <Box display="Flex" flexDirection="column" flex={1}>
                      <Field name="zone">
                        {({ field }) => (
                          <CssTextField
                            {...field}
                            label={"ناحیه"}
                            variant="outlined"
                            style={{ width: "250px" }}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldValue("zone", e.target.value);
                            }}
                          />
                        )}
                      </Field>
                      <CustomErrorMessage name="zone" />
                    </Box>
                    <Box display="Flex" flexDirection="column" flex={1}>
                      <Field name="deviceSerial">
                        {({ field }) => (
                          <CssTextField
                            {...field}
                            label={"شماره سریال حسگرینو"}
                            variant="outlined"
                            style={{ width: "250px" }}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldValue(
                                "deviceSerial",
                                e.target.value
                              );
                            }}
                          />
                        )}
                      </Field>
                      <CustomErrorMessage name="deviceSerial" />
                    </Box>
                    <Box display="Flex" flexDirection="column" flex={1}>
                      <Field name="latitude">
                        {({ field }) => (
                          <CssTextField
                            {...field}
                            label={"Latitude"}
                            variant="outlined"
                            style={{ width: "250px" }}
                            type="number"
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only float values
                              if (/^\d*\.?\d*$/.test(value)) {
                                formik.handleChange(e);
                                formik.setFieldValue("latitude", value);
                              }
                            }}
                          />
                        )}
                      </Field>
                      <CustomErrorMessage name="latitude" />
                    </Box>
                    <Box display="Flex" flexDirection="column" flex={1}>
                      <Field name="longitude">
                        {({ field }) => (
                          <CssTextField
                            {...field}
                            label={"Longitude"}
                            variant="outlined"
                            style={{ width: "250px" }}
                            type="number"
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only float values
                              if (/^\d*\.?\d*$/.test(value)) {
                                formik.handleChange(e);
                                formik.setFieldValue("longitude", value);
                              }
                            }}
                          />
                        )}
                      </Field>

                      <CustomErrorMessage name="mobile" />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: lang === "Fa" ? "row" : "row-reverse",
                    justifyContent: "space-between",
                    gap: "1rem",
                    width: "50%",
                    mt: "1rem",
                  }}
                >
                  <CustomButton
                    secondary
                    sx={{ flex: "1 1" }}
                    onClick={() => {
                      formik.resetForm();
                    }}
                  >
                    <Typography variant="h4">{t("cancel")}</Typography>
                  </CustomButton>
                  <CustomButton type="submit" sx={{ flex: "1 1" }}>
                    <Typography variant="h4">{t("record")}</Typography>
                  </CustomButton>
                </Box>
              </Box>
            </Form>
          </CacheProvider>
        </FormikProvider>
      </Box>
    </WidgetWrapper>
  );
};

export default AdminHesgarino;
