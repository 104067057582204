import { Box, useTheme } from "@mui/material";
import BoxSensorsManagement from "components/BoxSensorsManagement/BoxSensorsManagement";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const SensorCards = ({ cards, toggle }) => {
  console.log("🚀 ~ file: SensorCards.jsx:7 ~ SensorCards ~ cards:", cards);

  const convertedData = [];

  for (const key in cards) {
    if (cards.hasOwnProperty(key)) {
      const data = cards[key].map((item) => ({
        ts: item.ts,
        value: item.value,
      }));

      convertedData.push({ key, data });
    }
  }
  console.log(
    "🚀 ~ file: SensorCards.jsx:10 ~ SensorCards ~ convertedData:",
    convertedData
  );
  const theme = useTheme();
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [cards]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: "27rem",
        zIndex: "1000",
        width: "99%",
        height: "163px",
        p: "0 2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem",
      }}
    >
      {convertedData
        ? convertedData.map((item, index) => {
            return item.key !== "ToA" && item.key !== "SC" ? (
              <motion.div
                key={`${item.key}-${animationKey}`}
                initial={{ scale: 0.7 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.25 * (index + 1) }}
              >
                <BoxSensorsManagement
                  type={item.key}
                  data={item.data}
                  color={theme.palette.others.third}
                  toggle={toggle}
                />
              </motion.div>
            ) : null;
          })
        : null}
    </Box>
  );
};

export default SensorCards;
