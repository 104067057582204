import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useEffect, useState } from "react";

const BoxSensorinoCard = ({ data, type, toggle }) => {
  return (
    <Box
      sx={{
        minHeight: "100px",
        backgroundColor: "others.fifth",
        backdropFilter: "blur(7.5px)",
        borderRadius: "8px",
        p: "0.5rem 1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.5rem",
      }}
    >
      <Typography fontSize="16px" color={"fontColor.main"}>
        {type}
      </Typography>
      <Typography
        variant="h1"
        color={"fontColor.main"}
        sx={{ direction: "ltr" }}
      >
        {type === "تغییرات" ? data + "%" : data}
      </Typography>
    </Box>
  );
};

export default BoxSensorinoCard;
