import React from "react";
import { MapContainer, Marker, Polygon, TileLayer } from "react-leaflet";
import { getIcon } from "./GetIcon";
import { motion } from "framer-motion";
import { MapBox } from "./SensorsManagementAll";
import Skelton from "components/Skelton/Skelton";
import { getDataByType } from "Utils/Utils";

export const MapAllSensors = ({
  mode,
  simplifiedA,
  telemetryAllDataQuery,
  setTitleDevice,
  setLastTimeUpdate,
  setselectedIcon,
  setAnimationKey,
  animationKey,
  setCards,
  selectedIcon,
  height,
}) => {
  console.log("🚀 ~ telemetryAllDataQuery:", telemetryAllDataQuery);
  return simplifiedA ? (
    <MapBox mode={mode}>
      <MapContainer
        bounds={simplifiedA}
        zoom={15}
        style={{
          borderRadius: "8px",
          height: height ? height : "616px",
          width: "100%",
        }}
      >
        {/* <ChangeView center={position} zoom={15} /> */}
        {/* <Polygon
        pathOptions={{
          color: "yellow",
          stroke: true,
          fillOpacity: 0,
        }}
        positions={simplifiedA}
      /> */}
        <TileLayer
          attribution="PTR"
          url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
          subdomains={["mt0", "mt1", "mt2", "mt3"]}
          maxZoom={22}
          maxNativeZoom={18}
        />
        {telemetryAllDataQuery.isSuccess &&
          telemetryAllDataQuery.data.map((item, index) => {
            console.log(
              "🚀 ~ file: SensorsManagementAll.jsx:1626 ~ fetchAllDataSensors.data.map ~ item:",
              item
            );
            if (item.name !== "gateway")
              return (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                  <Marker
                    eventHandlers={{
                      click: (e) => {
                        setTitleDevice(item.name);
                        setLastTimeUpdate(item.active.lastUpdateTs);
                        setselectedIcon(item.id);
                        setAnimationKey(animationKey + 1);
                        setCards(getDataByType(item?.lastData, item?.label));
                      },
                    }}
                    key={index}
                    position={[item.position.latitude, item.position.longitude]}
                    icon={
                      // allSensorsDataQuery.isSuccess.data && item.lastData
                      getIcon(item, selectedIcon)
                      // : GG1Icon
                    }
                  ></Marker>
                </motion.div>
              );
          })}
      </MapContainer>
    </MapBox>
  ) : (
    <Skelton />
  );
};
