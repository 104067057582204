import E1 from "../../assets/experts/1.png";
import E2 from "../../assets/experts/2.png";
import E3 from "../../assets/experts/3.png";
import E4 from "../../assets/experts/4.png";
import E5 from "../../assets/experts/5.png";
import E6 from "../../assets/experts/6.png";
import E7 from "../../assets/StaffImg/1.jpg";
import E8 from "../../assets/StaffImg/2.jpg";
import E9 from "../../assets/StaffImg/3.jpg";
import E10 from "../../assets/StaffImg/4.jpg";
import E11 from "../../assets/StaffImg/5.jpg";
import E12 from "../../assets/StaffImg/6.jpg";

import WeatherLight from "assets/Wallet/weather-light.svg";
import AlarmsLight from "assets/Wallet/alarms-light.svg";
import RemoteSensingLight from "assets/Wallet/remote-sensing-light.svg";
import ActuatorsLight from "assets/Wallet/actuator-light.svg";
import StaffMngmntLight from "assets/Wallet/staffmgmnt-light.svg";
import SensorsLight from "assets/Wallet/sensors-light.svg";
import SmartIndexLight from "assets/Wallet/smart-index-light.svg";
import GrowthStageLight from "assets/Wallet/growth-stage-light.svg";
import RecomsLight from "assets/Wallet/recoms-light.svg";
import { Box } from "@mui/material";
export const ServicesData = [
  {
    title: "هواشناسی",
    type: "weather",
    active: true,
    icon: {
      light: (
        <Box
          component="img"
          src={WeatherLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2536_61231)">
            <path
              d="M43.8009 27.92C44.3409 31.26 43.4009 34.84 40.5409 37.36C38.5609 39.18 35.9609 40.18 33.2609 40.16H11.0809C1.74086 39.48 1.72086 25.88 11.0809 25.2H11.1809C6.80086 12.94 18.1809 5.74002 26.7609 8.50002"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.5197 26.02C13.4797 25.5 12.3397 25.22 11.1797 25.2"
              stroke="#F7F7F7"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M43.9394 17C43.9394 19.2 42.9195 21.18 41.2995 22.46C40.1195 23.42 38.5795 24 36.9395 24C33.0795 24 29.9395 20.86 29.9395 17C29.9395 15.08 30.7195 13.34 31.9995 12.08V12.06C33.2595 10.78 35.0195 10 36.9395 10C40.7994 10 43.9394 13.14 43.9394 17Z"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2536_61231">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  },
  {
    title: "هشدارها",
    type: "alarm",
    active: false,
    icon: {
      light: (
        <Box
          component="img"
          src={AlarmsLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 18.5V27.6667"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24.0003 41.2517H12.8903C6.52862 41.2517 3.87028 36.705 6.95028 31.15L12.6703 20.8467L18.0603 11.1667C21.3236 5.28168 26.6769 5.28168 29.9403 11.1667L35.3303 20.865L41.0503 31.1683C44.1303 36.7233 41.4536 41.27 35.1103 41.27H24.0003V41.2517Z"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23.9902 33.1667H24.0067"
            stroke="#F7F7F7"
            stroke-width="3.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  },

  {
    title: "سنجش از راه دور",
    type: "remoteSensing",
    active: false,
    icon: {
      light: (
        <Box
          component="img"
          src={RemoteSensingLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.8993 41.9851H34.266C36.8877 41.9851 39.3994 39.8584 39.8394 37.2734L42.2777 22.6801C42.571 20.8834 41.6543 18.4817 40.2427 17.345L27.5377 7.18829C25.576 5.61162 22.4044 5.61161 20.461 7.16994L7.75604 17.345C6.32604 18.4817 5.40935 20.8834 5.72101 22.6801L6.23434 25.76"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.66602 36.8333C8.89268 36.9066 11.0927 39.1066 11.166 42.3333"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.666 42.3333C16.6477 40.94 16.4094 39.6383 15.9877 38.4467C14.906 35.385 12.596 33.0933 9.55267 32.0116C8.36101 31.59 7.05935 31.3516 5.66602 31.3333"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M5.65842 42.3334H5.67492"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  },
  {
    title: "عملگرها",
    type: "actuator",
    active: true,
    icon: {
      light: (
        <Box
          component="img"
          src={ActuatorsLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.3327 9.33331H27.666C35.7693 9.33331 42.3327 15.8966 42.3327 24C42.3327 32.1033 35.7693 38.6666 27.666 38.6666H20.3327C12.2293 38.6666 5.66602 32.1033 5.66602 24C5.66602 15.8966 12.2293 9.33331 20.3327 9.33331Z"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.3333 31.3334C24.3834 31.3334 27.6667 28.0501 27.6667 24C27.6667 19.9499 24.3834 16.6667 20.3333 16.6667C16.2832 16.6667 13 19.9499 13 24C13 28.0501 16.2832 31.3334 20.3333 31.3334Z"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  },
  {
    title: "مدیریت کارکنان",
    type: "staffManagement",
    active: true,
    icon: {
      light: (
        <Box
          component="img"
          src={StaffMngmntLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.7927 21.9284C18.6094 21.91 18.3894 21.91 18.1877 21.9284C13.8244 21.7817 10.3594 18.2067 10.3594 13.8067C10.3594 9.31502 13.9894 5.66669 18.4994 5.66669C22.991 5.66669 26.6394 9.31502 26.6394 13.8067C26.621 18.2067 23.156 21.7817 18.7927 21.9284Z"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M32.084 9.33331C35.6407 9.33331 38.5007 12.2116 38.5007 15.75C38.5007 19.215 35.7507 22.0383 32.3224 22.1666C32.1757 22.1483 32.0107 22.1483 31.8457 22.1666"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.62633 28.6933C5.18966 31.6633 5.18966 36.5033 9.62633 39.455C14.668 42.8283 22.9363 42.8283 27.978 39.455C32.4147 36.485 32.4147 31.645 27.978 28.6933C22.9547 25.3383 14.6863 25.3383 9.62633 28.6933Z"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.623 38.6667C36.943 38.3917 38.1897 37.86 39.2164 37.0717C42.0764 34.9267 42.0764 31.3884 39.2164 29.2434C38.208 28.4734 36.9797 27.96 35.678 27.6667"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  },
  {
    title: "مدیریت سنسور ها",
    type: "sensorManagement",
    active: false,
    icon: {
      light: (
        <Box
          component="img"
          src={SensorsLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.9042 22.7901C28.6192 19.7834 25.9975 17.1616 22.9909 17.8766C21.5792 18.225 20.4242 19.38 20.0759 20.7917C19.3609 23.7984 21.9825 26.42 24.9892 25.705C26.4192 25.3567 27.5742 24.2017 27.9042 22.7901Z"
            stroke="#F7F7F7"
            stroke-width="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.0181 33.515C38.1531 30.5817 40.1147 26.42 40.1147 21.7816C40.1147 12.8716 32.8914 5.66669 23.9998 5.66669C15.1081 5.66669 7.88477 12.89 7.88477 21.7816C7.88477 26.4383 9.86476 30.6366 13.0364 33.57"
            stroke="#F7F7F7"
            stroke-width="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.6672 28.675C14.9806 26.8783 13.9355 24.4583 13.9355 21.7817C13.9355 16.2267 18.4456 11.7167 24.0006 11.7167C29.5556 11.7167 34.0656 16.2267 34.0656 21.7817C34.0656 24.4583 33.0206 26.86 31.3339 28.675"
            stroke="#F7F7F7"
            stroke-width="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.8827 32.5433L18.2427 35.825C16.1527 38.4467 18.0043 42.315 21.3593 42.315H26.621C29.976 42.315 31.846 38.4284 29.7377 35.825L27.0977 32.5433C25.521 30.545 22.4777 30.545 20.8827 32.5433Z"
            stroke="#F7F7F7"
            stroke-width="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  },
  {
    title: "شاخص هوشمند",
    type: "smartIndex",
    active: false,
    icon: {
      light: (
        <Box
          component="img"
          src={SmartIndexLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="49"
          height="48"
          viewBox="0 0 49 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2536_62612)">
            <path
              d="M18.7051 44H30.7051C40.7051 44 44.7051 40 44.7051 30V18C44.7051 8 40.7051 4 30.7051 4H18.7051C8.70508 4 4.70508 8 4.70508 18V30C4.70508 40 8.70508 44 18.7051 44Z"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.3652 28.98L20.1252 22.8C20.8052 21.92 22.0652 21.76 22.9452 22.44L26.6052 25.32C27.4852 26 28.7452 25.84 29.4252 24.98L34.0452 19.02"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2536_62612">
              <rect
                width="48"
                height="48"
                fill="white"
                transform="translate(0.705078)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  },
  {
    title: "مراحل رشد گیاه",
    type: "growth",
    active: false,
    icon: {
      light: (
        <Box
          component="img"
          src={GrowthStageLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_2536_61264)">
            <path
              d="M25.2206 4.42C24.5006 3.86 23.5006 3.86 22.7806 4.42C18.9806 7.32 7.76055 16.78 7.82055 27.8C7.82055 36.72 15.0806 44 24.0206 44C32.9606 44 40.2206 36.74 40.2206 27.82C40.2406 16.96 29.0006 7.34 25.2206 4.42Z"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-miterlimit="10"
            />
            <path
              d="M24 4V44"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24 37.92L39.4 30.44"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24 27.92L38.74 20.76"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M24 17.92L34.0601 13.02"
              stroke="#F7F7F7"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_2536_61264">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
    },
  },
  {
    title: "توصیه ها",
    type: "recommend",
    active: false,
    icon: {
      light: (
        <Box
          component="img"
          src={RecomsLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.5031 22.8089C10.7301 22.4151 9.86953 22.2109 8.99442 22.2109C2.16853 22.6922 2.16853 32.6248 8.99442 33.1061H25.1695C27.1385 33.1207 29.0345 32.3914 30.4785 31.0642C35.277 26.8782 32.71 18.4625 26.3946 17.6603C24.1193 3.99394 4.38548 9.18629 9.06735 22.2109"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24.0176 18.3031C24.776 17.9239 25.6074 17.7197 26.4533 17.7051"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M41.4463 27.8363C43.0574 27.8363 44.3634 26.5303 44.3634 24.9192C44.3634 23.3082 43.0574 22.0022 41.4463 22.0022C39.8353 22.0022 38.5293 23.3082 38.5293 24.9192C38.5293 26.5303 39.8353 27.8363 41.4463 27.8363Z"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M41.4463 16.1678C43.0574 16.1678 44.3634 14.8618 44.3634 13.2507C44.3634 11.6397 43.0574 10.3337 41.4463 10.3337C39.8353 10.3337 38.5293 11.6397 38.5293 13.2507C38.5293 14.8618 39.8353 16.1678 41.4463 16.1678Z"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M41.4463 39.5048C43.0574 39.5048 44.3634 38.1987 44.3634 36.5877C44.3634 34.9767 43.0574 33.6707 41.4463 33.6707C39.8353 33.6707 38.5293 34.9767 38.5293 36.5877C38.5293 38.1987 39.8353 39.5048 41.4463 39.5048Z"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M34.1523 24.9185L38.5279 24.9185"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M38.53 13.2511H32.6959C29.7788 13.2511 28.3203 14.7096 28.3203 17.6267M28.3203 32.9412C28.3203 35.129 29.7788 36.5875 32.6959 36.5875H38.53"
            stroke="#F7F7F7"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  },
  {
    title: "حسگرینو",
    type: "sensorino",
    active: false,
    icon: {
      light: (
        <Box
          component="img"
          src={SensorsLight}
          sx={{ width: "48px", height: "48px" }}
        />
      ),
      dark: (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.9042 22.7901C28.6192 19.7834 25.9975 17.1616 22.9909 17.8766C21.5792 18.225 20.4242 19.38 20.0759 20.7917C19.3609 23.7984 21.9825 26.42 24.9892 25.705C26.4192 25.3567 27.5742 24.2017 27.9042 22.7901Z"
            stroke="#F7F7F7"
            stroke-width="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M35.0181 33.515C38.1531 30.5817 40.1147 26.42 40.1147 21.7816C40.1147 12.8716 32.8914 5.66669 23.9998 5.66669C15.1081 5.66669 7.88477 12.89 7.88477 21.7816C7.88477 26.4383 9.86476 30.6366 13.0364 33.57"
            stroke="#F7F7F7"
            stroke-width="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.6672 28.675C14.9806 26.8783 13.9355 24.4583 13.9355 21.7817C13.9355 16.2267 18.4456 11.7167 24.0006 11.7167C29.5556 11.7167 34.0656 16.2267 34.0656 21.7817C34.0656 24.4583 33.0206 26.86 31.3339 28.675"
            stroke="#F7F7F7"
            stroke-width="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.8827 32.5433L18.2427 35.825C16.1527 38.4467 18.0043 42.315 21.3593 42.315H26.621C29.976 42.315 31.846 38.4284 29.7377 35.825L27.0977 32.5433C25.521 30.545 22.4777 30.545 20.8827 32.5433Z"
            stroke="#F7F7F7"
            stroke-width="2.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  },
];
export const expertFields = [
  "IRRIGATION",
  "SOIL_FERTILITY",
  "PLANT",
  "POISON_PEST",
];

export const expertData = [
  {
    id: "1JSRTV",
    name: "امیر ناصری",
    rate: 4,
    pic: E1,
    active: ["watering", "poison"],
    fields: ["watering", "soil", "poison"],
    loc: "تهران",
    serial: "293485",
  },
  {
    id: "2EJ5RTG",
    name: "علی مرادی",
    rate: 4,
    pic: E2,
    active: ["watering", "soil"],
    fields: ["watering", "soil"],
    loc: "اردبیل",
    serial: "352452",
  },
  {
    id: "3VERG",
    name: "هانیه رضایی",
    rate: 4,
    pic: E3,
    active: ["poison"],
    fields: ["watering", "soil", "poison", "plant"],
    loc: "تهران",
    serial: "834534",
  },
  {
    id: "4JNERT",
    name: "حسین محمدی",
    rate: 4,
    pic: E4,
    active: ["poison"],
    fields: ["watering", "soil", "poison"],
    loc: "مازندران",
    serial: "456345",
  },
  {
    id: "5GRTG",
    name: "سینا هوشمند",
    rate: 4,
    pic: E5,
    active: ["plant"],
    fields: ["watering", "soil", "poison"],
    loc: "تهران",
    serial: "834552",
  },
  {
    id: "6QWER",
    name: "امید اکبری",
    rate: 4,
    pic: E6,
    active: ["soil"],
    fields: ["watering", "soil", "poison", "plant"],
    loc: "تهران",
    serial: "934562",
  },
  {
    id: "IMDGK",
    name: "اصغر رضایی",
    rate: 4,
    pic: E7,
    active: ["soil"],
    fields: ["watering", "soil", "poison", "plant"],
    loc: "تهران",
    serial: "256234",
  },
  {
    id: "GJKD>",
    name: "حامد غلامی",
    rate: 4,
    pic: E8,
    active: ["soil"],
    fields: ["watering", "soil", "poison", "plant"],
    loc: "سمنان",
    serial: "845623",
  },
  {
    id: "REKDB",
    name: "احمد رضایی",
    rate: 4,
    pic: E9,
    active: ["soil"],
    fields: ["watering", "soil", "poison", "plant"],
    loc: "چابهار",
    serial: "723463",
  },
  {
    id: "7UJDS",
    name: "محمد حسینی",
    rate: 4,
    pic: E10,
    active: ["soil"],
    fields: ["watering", "soil", "poison", "plant"],
    loc: "اصفهان",
    serial: "724562",
  },
  {
    id: "RYKS<CH",
    name: "حمید فراهانی",
    rate: 4,
    pic: E11,
    active: ["soil"],
    fields: ["watering", "soil", "poison", "plant"],
    loc: "اصفهان",
    serial: "245623",
  },
  {
    id: "OKHFNCH",
    name: "سعید ملایی",
    rate: 4,
    pic: E12,
    active: ["soil"],
    fields: ["watering", "soil", "poison", "plant"],
    loc: "اصفهان",
    serial: "234523",
  },
];
export const expertFieldTitle = (field) => {
  switch (field) {
    case "watering":
      return "کارشناس آبیاری";
    case "soil":
      return "کارشناس خاک و حاصلخیزی";
    case "poison":
      return "کارشناس سم و آفات";
    case "plant":
      return "کارشناس گیاه";
    default:
      break;
  }
};
