import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getDateShamsiAirSensors } from "Utils/Utils";

Chart.register(...registerables);
Chart.register(annotationPlugin);
const LineFillupY = ({
  th2,
  sensorData,
  // min,
  // max,
  stepSize,
  thv1,
  thv2,
  h,
}) => {
  const theme = useTheme();
  const [flag, setFlag] = useState(true);
  const dataLabel = [];
  const dataSensor = [];
  const chartBarData = [];

  const firstDate = useSelector((state) => state.sensors.date1);
  const secondDate = useSelector((state) => state.sensors.date2);

  function areDatesEqual(date1, date2) {
    return (
      date1.year === date2.year &&
      date1.month === date2.month &&
      date1.day === date2.day
    );
  }
  if (sensorData) {
    console.log("🚀 ~ file: LineNormal.jsx:76 ~ sensorData:", sensorData);
    sensorData?.data.map((item) => {
      const date = new Date(item.ts);

      const persianDate = date.toLocaleDateString("fa-IR-u-ca-persian", {
        // year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });

      // const persianTime = date.toLocaleTimeString("fa-IR-u-ca-persian", {
      //   hour: "2-digit",
      //   minute: "2-digit",
      //   hour12: false,
      // });

      // dataLabel.push(
      //   areDatesEqual(firstDate, secondDate) ? persianDate : persianDate
      // );

      dataLabel.push(persianDate);
      dataSensor.push(item.value);

      chartBarData.push(50);
    });
  }
  console.log(
    "🚀 ~ file: LineNormal.jsx:105 ~ sensorData[0].data.map ~ dataLabel:",
    dataLabel
  );

  const dailyData = {};

  sensorData?.data.forEach((item) => {
    const timestamp = item.ts;
    const date = new Date(timestamp);
    const dayKey = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    if (!dailyData[dayKey]) {
      dailyData[dayKey] = { sum: 0, count: 0 };
    }

    dailyData[dayKey].sum += parseFloat(item.value); // Assuming "value" is a string that needs to be converted to a number
    dailyData[dayKey].count += 1;
  });
  console.log("🚀 ~ file: LineNormal.jsx:108 ~ dailyData:", dailyData);

  // Calculate the mean for each day
  const dailyMeans = {};

  for (const dayKey in dailyData) {
    const { sum, count } = dailyData[dayKey];
    dailyMeans[dayKey] = sum / count;
  }
  console.log("🚀 ~ file: LineNormal.jsx:127 ~ dailyMeans:", dailyMeans);

  const label = Object.keys(dailyMeans).reverse(); // Extract and reverse the keys
  console.log("🚀 ~ file: LineNormal.jsx:135 ~ label:", label);
  const shamsiLabel = label.map((item) => getDateShamsiAirSensors(item));
  const dataChart = label.map((label) => dailyMeans[label]);
  console.log("🚀 ~ file: LineNormal.jsx:135 ~ dataChart:", dataChart);
  const chartData = sensorData ? dataChart : [0, 0, 0, 0, 0, 0, 0];
  const min = Math.round(Math.min(...chartData) * 0.8);
  const max = Math.round(Math.max(...chartData) * 1.2);
  const dataLabels = sensorData ? shamsiLabel : [1, 2, 3, 4, 5, 6, 7];
  console.log("🚀 ~ file: LineNormal.jsx:142 ~ dataLabels:", dataLabels);

  const backgroundcolor = [];
  for (let i = 0; i < chartData.length; i++) {
    if (chartData[i] < 25) {
      backgroundcolor.push("rgba(47,128,237,0.3");
    }
    if (chartData[i] >= 25) {
      backgroundcolor.push("rgba(252,73,73,0.3");
    }
  }
  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const down = (ctx, value) =>
    ctx.p1.parsed.y > 25 || ctx.p0.parsed.y > 25 ? value : undefined;
  const data = {
    labels: dataLabels,
    datasets: [
      // {
      //   type: "bar",
      //   label: "First dataset",
      //   data: chartBarData,
      //   barThickness: 25,
      //   backgroundColor: "rgba(42,128,244,0.1)",
      //   options: {
      //     tooltips: { enabled: false },
      //   },
      // },
      {
        lineTension: 0,
        label: " ",
        data: chartData,
        showLine: true,
        fill: {
          target: {
            value: 0,
          },
          above: "rgba(47,128,237,0.3)",
          below: "rgba(47,128,237,0.3)",
        },
        backgroundColor: backgroundcolor,
        borderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          //   30
          return value > 25
            ? theme.palette.others.forth
            : theme.palette.others.first;
        },
        segment: {
          backgroundColor: (ctx) =>
            skipped(ctx, "rgba(252,73,73,0.3") ||
            down(ctx, "rgba(255,238,0,0.3)"),
          borderColor: (ctx) =>
            skipped(ctx, theme.palette.others.forth) ||
            down(ctx, theme.palette.others.forth),
          //   borderDash: (ctx) => skipped(ctx, [6, 6]),
          //   fill: {
          //     target: {
          //       value: 30,
          //     },
          //     above: "red",
          //     below: "green",
          //   },
        },
        spanGaps: true,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];

          return value > 25 ? "yellow" : "blue";
        },
        pointBackgroundColor: "blue",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };
  return (
    <Box
      mt="1.5rem"
      sx={{
        width: "100%",
        height: h ? h : "160px",
      }}
    >
      {/* /////////////////// */}
      {flag ? (
        <Line
          data={data}
          options={{
            elements: {
              point: {
                radius: 0,
              },
            },

            plugins: {
              tooltips: {
                mode: "nearest",
                intersect: true,
                backgroundColor: "yellow",
                titleFontColor: "#000",
                titleFontSize: 16,
                bodyFontColor: "#000",
                bodyFontSize: 14,
              },
              annotation: {
                annotations: {
                  line1: {
                    type: "line",
                    yMin: thv1,
                    yMax: thv1,
                    borderColor: theme.palette.others.second,
                    borderWidth: 1,
                  },
                  line2: {
                    type: "line",
                    yMin: thv2,
                    yMax: thv2,
                    borderColor: theme.palette.others.second,
                    borderWidth: 1,
                  },
                  line3: {
                    type: "line",
                    yMin: 0,
                    yMax: 0,
                    borderColor: "white",
                    borderWidth: 1,
                  },
                },
              },
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,

            scales: {
              y: {
                min: min,
                max: max,
                title: {
                  display: false,
                  text: "",
                },

                grid: {
                  color: theme.palette.background.second,
                  borderDash: [3, 3],
                },
                ticks: {
                  stepSize: stepSize,
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              x: {
                title: {
                  display: false,
                  text: "",
                },
                grid: {
                  borderDash: [3, 3],
                  color: theme.palette.background.second,
                },
                ticks: {
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],

              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    suggestedMin: 0,
                  },
                },
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default LineFillupY;
