import { Box, styled, Typography } from "@mui/material";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import FlexBetween from "components/FlexBetween/FlexBetween";

import MixedChart from "components/MixedChart/MixedChart";
import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ButtonsSensorsManagementAll from "./ButtonsSensorsManagementAll";
import CopyRight from "widgets/CopyRight";
import GG1Icon1 from "../../assets/Sensor Icon/G-G 1.png";

import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import SensorCards from "./SensorCards";
import { useQuery } from "react-query";
import {
  fetchAllDataSensors,
  fetchAllTelemetryData,
  getLandActive,
  landData,
} from "Services/Http/http.service";
import { getDateShamsiAirSensors } from "Utils/Utils";
import Skelton from "components/Skelton/Skelton";
import { MapAllSensors } from "./MapAllSensors";

export const MapBox = styled(Box)(({ theme, mode }) => ({
  position: "absolute",
  width: "100% !important",
  // maxheight: "100%",
  padding: "0px",

  maxHeight: "100%",
  "& .leaflet-left": {
    left: "14px",
  },
  "& .leaflet-bar": {
    border: "none",
  },

  "& .leaflet-control-zoom-in": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7) !important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "8px 8px 0 0 !important",
  },

  "& .leaflet-touch .leaflet-bar a": {
    lineHeight: "24px !important",
  },
  "& .leaflet-control-zoom-out": {
    width: "24px !important",
    height: "24px !important",
    backgroundColor:
      mode === "dark"
        ? "rgba(29, 29, 65, 0.7 )!important"
        : "rgba(250, 250, 252, 0.7) !important",
    color: theme.palette.fontColor.alt,
    borderRadius: "0 0 8px 8px !important",
  },
}));

// const ChangeView = ({ center, zoom }) => {
//   const map = useMap();
//   map.setView(center, zoom);
//   return null;
// };

const SensorsManagementAll = ({ telemetryAllDataQuery }) => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const { ref: ref1, inView: inView1 } = useInView();
  const { ref: ref2, inView: inView2 } = useInView();
  const [lastTimeUpdate, setLastTimeUpdate] = useState("");
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:93 ~ SensorsManagementAll ~ lastTimeUpdate:",
    lastTimeUpdate
  );
  const Box1Animation = useAnimation();
  const Box2Animation = useAnimation();
  const Box3Animation = useAnimation();
  const Box4Animation = useAnimation();

  const [animationKey, setAnimationKey] = useState(0);
  const token = useSelector((state) => state.global.token);
  const [mapModal, setMapModal] = useState(false);
  const [titleDevice, setTitleDevice] = useState("");
  // const lastTimeUpdate = useSelector((state) => state.sensors.lastTimeUpdate);

  const navigate = useNavigate();

  useEffect(() => {
    if (inView1) {
      if (!show) {
        Box1Animation.start({
          scale: 1,
          // y: 0,
          opacity: 1,

          transition: {
            type: "spring",
            duration: 1,
            delay: 0.1,
            // bounce: 0.1,
          },
        });
        Box2Animation.start({
          scale: 1,
          // y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            duration: 1,
            delay: 0.2,
            // bounce: 0.1,
          },
        });

        setShow(true);
      }
    }
    if (!inView1) {
      if (!show) {
        Box1Animation.start({
          scale: 0.8,
          // y: 100,
          opacity: 0.1,
        });
        Box2Animation.start({
          scale: 0.8,
          // y: 100,
          opacity: 0.1,
        });
      }
    }
    if (inView2) {
      if (!show2) {
        Box3Animation.start({
          scale: 1,
          // y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            duration: 1,
            delay: 0.3,
            // bounce: 0.1,
          },
        });
        Box4Animation.start({
          scale: 1,
          // y: 0,
          opacity: 1,
          transition: {
            type: "spring",
            duration: 1,
            delay: 0.4,
            // bounce: 0.1,
          },
        });

        setShow2(true);
      }
    }
    if (!inView2) {
      if (!show2) {
        Box3Animation.start({
          scale: 0.8,
          // y: 100,
          opacity: 0.1,
        });
        Box4Animation.start({
          scale: 0.8,
          // y: 100,
          opacity: 0.1,
        });
      }
    }
  }, [token, inView1, inView2]);
  const [typeSelect, setTypeSelect] = useState("WEATHER");

  const [zoneSelect, setZoneSelect] = useState("همه");

  const [cards, setCards] = useState(null);
  // const data = useSelector((state) => state.sensors.sensorsData.devices);

  // const d = useSelector((state) => state.sensors);
  // Get the current date
  const currentDate = new Date();

  // Get the date 7 days ago
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(currentDate.getDate() - 7);

  // Generate an array of dates from 7 days ago to now
  const timestampsArray = [];
  let currentDateIterator = new Date(sevenDaysAgo); // Create a new Date object to avoid reference issues
  while (currentDateIterator <= currentDate) {
    timestampsArray.push(new Date(currentDateIterator));
    currentDateIterator.setDate(currentDateIterator.getDate() + 1);
  }

  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:589 ~ SensorsManagementAll ~ timestampsArray:",
    timestampsArray
  );

  // Getting unique titles using a Set

  // const d = data.sort((a, b) => {
  //   const typeA = order.indexOf(a.type);
  //   const typeB = order.indexOf(b.type);
  //   return typeA - typeB;
  // });

  // Function to get the Unix timestamp of a specific date

  // useEffect(() => {
  //   if (token === null) {
  //     navigate("/login");
  //   } else {
  //   }
  //   // let xx = data.map((d) => d.location.x);
  //   // let x = xx.reduce((a, b) => a + b) / xx.length;
  //   // let yy = data.map((d) => d.location.y);
  //   // let y = yy.reduce((a, b) => a + b) / yy.length;
  //   // setPosition([x, y]);
  // }, [data, token]);

  const [position, setPosition] = useState([35.643029, 52.224928]);

  const landActiveQuery = useQuery(["landActiveQuery", token], () => {
    return getLandActive(token);
  });
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:241 ~ landActiveQuery ~ landActiveQuery:",
    landActiveQuery
  );

  const simplifiedA = landActiveQuery.isSuccess
    ? landActiveQuery.data.data.land.coordinates.map((item) => [
        item.latitude,
        item.longitude,
      ])
    : [];
  console.log(
    "🚀 ~ file: SensorsManagementAll.jsx:247 ~ SensorsManagementAll ~ simplifiedA:",
    simplifiedA
  );

  useEffect(() => {}, [position]);
  const [toggle, setToggle] = useState(false);
  const [selectedIcon, setselectedIcon] = useState(null);
  const mode = useSelector((state) => state.global.mode);
  const firstDate = useSelector((state) => state.sensors.firstDate);

  const secondDate = useSelector((state) => state.sensors.secondDate);
  var GG1Icon = L.icon({
    iconUrl: GG1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
  });

  // const allSensorsDataQuery = useQuery(
  //   ["allSensorsDataQuery", token.token],
  //   () => {
  //     return fetchAllDataSensors(token.token);
  //   }
  // );

  // console.log(
  //   "🚀 ~ file: SensorsManagementAll.jsx:1201 ~ allSensorsDataQuery ~ allSensorsDataQuery:",
  //   allSensorsDataQuery
  // );

  const [devices, setDevices] = useState([]);

  const desiredName = "soil 1"; // Example name

  const filteredItem = telemetryAllDataQuery.isSuccess
  
    ? telemetryAllDataQuery.data?.find((item) => item.name === desiredName)
    : [];

  let desiredData = null;

  // if (filteredItem) {
  //   desiredData = filteredItem.lastData[desiredDataType];
  // }

  // console.log("desiredData=======>", desiredData);

  return (
    <Box
      position="relative"
      width="99.5%"
      height="83vh"
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {lastTimeUpdate !== "" && (
        <Box
          sx={{
            width: "182px",
            height: "35px",
            backgroundColor: "others.fifth",
            backdropFilter: "blur(7.5px)",
            position: "absolute",
            top: "15px",
            left: "70px",
            zIndex: "1000",
            borderRadius: "8px",
            direction: "ltr",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body" color="fontColor.main">
            {getDateShamsiAirSensors(lastTimeUpdate)}
          </Typography>
        </Box>
      )}
      {!!titleDevice && (
        <Box
          sx={{
            width: "182px",
            height: "35px",
            backgroundColor: "others.fifth",
            backdropFilter: "blur(7.5px)",
            position: "absolute",
            top: "15px",
            left: "260px",
            zIndex: "1000",
            borderRadius: "8px",
            direction: "ltr",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body" color="fontColor.main">
            {titleDevice}
          </Typography>
        </Box>
      )}
      <FlexBetween
        gap="0.5rem"
        sx={{
          position: "absolute",
          zIndex: "1000",
          top: "15px",
          right: "10px",
        }}
      >
        {/* <CustomSelectBox
          width="157px"
          height="35px"
          type={"type"}
          background="others.fifth"
          backdropFilter={"blur(7.5px)"}
          typeSelect={typeSelect}
          setTypeSelect={setTypeSelect}
          zoneSelect={zoneSelect}
          setZoneSelect={setZoneSelect}
          setCards={setCards}
          setTitleDevice={setTitleDevice}
          weatherZones={weatherZones}
          soilZones={soilZones}
          plantZones={plantZones}
        />
        <CustomSelectBox
          width="157px"
          height="35px"
          type={"area"}
          background={"others.fifth"}
          backdropFilter={"blur(7.5px)"}
          typeSelect={typeSelect}
          setTypeSelect={setTypeSelect}
          zoneSelect={zoneSelect}
          setZoneSelect={setZoneSelect}
          setCards={setCards}
          setTitleDevice={setTitleDevice}
          weatherZones={weatherZones}
          soilZones={soilZones}
          plantZones={plantZones}
        /> */}
      </FlexBetween>
      <SensorCards cards={cards} toggle={toggle} />
      <ButtonsSensorsManagementAll
        setMapModal={setMapModal}
        toggle={toggle}
        setToggle={setToggle}
        individual={false}
      />

      <Box>
        <Box
          width="99.5%"
          height="616px"
          position="relative"
          display="flex"
          flexDirection="column"
        >
          {!telemetryAllDataQuery.isSuccess ? (
            <Skelton />
          ) : (
            <MapAllSensors
              mode={mode}
              simplifiedA={simplifiedA}
              telemetryAllDataQuery={telemetryAllDataQuery}
              setTitleDevice={setTitleDevice}
              setLastTimeUpdate={setLastTimeUpdate}
              setselectedIcon={setselectedIcon}
              setAnimationKey={setAnimationKey}
              animationKey={animationKey}
              setCards={setCards}
              selectedIcon={selectedIcon}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <>
            <Box
              ref={ref1}
              sx={{
                width: "99.5%",
                // height: "616px",
                mt: "1rem",
                display: "flex",
                justifyContent: "space-between",
                "& > div": {
                  backgroundColor: "background.second",
                },
              }}
            >
              <motion.div
                animate={Box1Animation}
                style={{
                  width: "49.5%",
                  height: "210px",
                  borderRadius: "8px",
                  padding: "0.5rem 0.75rem",
                }}
              >
                {telemetryAllDataQuery.isSuccess &&
                telemetryAllDataQuery.data.length > 0 ? (
                  <MixedChart data={telemetryAllDataQuery.data} index={0} />
                ) : (
                  <></>
                )}
              </motion.div>

              <motion.div
                animate={Box2Animation}
                style={{
                  width: "49.5%",
                  height: "210px",
                  borderRadius: "8px",
                  padding: "0.5rem 0.75rem",
                }}
              >
                {telemetryAllDataQuery.isSuccess &&
                telemetryAllDataQuery.data.length > 0 ? (
                  <MixedChart data={telemetryAllDataQuery.data} index={0} />
                ) : (
                  <></>
                )}
              </motion.div>
            </Box>
            <Box
              ref={ref2}
              sx={{
                width: "99.5%",
                // height: "616px",

                mt: "1rem",
                display: "flex",
                justifyContent: "space-between",
                "& > div": {
                  backgroundColor: "background.second",
                },
              }}
            >
             
              <motion.div
                animate={Box1Animation}
                style={{
                  width: "49.5%",
                  height: "210px",
                  borderRadius: "8px",
                  padding: "0.5rem 0.75rem",
                }}
              >
                {telemetryAllDataQuery.isSuccess &&
                telemetryAllDataQuery.data.length > 0 ? (
                  <MixedChart data={telemetryAllDataQuery.data} index={0} />
                ) : (
                  <></>
                )}
              </motion.div>
              <motion.div
                animate={Box2Animation}
                style={{
                  width: "49.5%",
                  height: "210px",
                  borderRadius: "8px",
                  padding: "0.5rem 0.75rem",
                }}
              >
                {telemetryAllDataQuery.isSuccess &&
                telemetryAllDataQuery.data.length > 0 ? (
                  <MixedChart data={telemetryAllDataQuery.data} index={0} />
                ) : (
                  <></>
                )}
              </motion.div>
            </Box>
          </>
        </Box>
      </Box>
      <Box width="92vw">
        <CopyRight />
      </Box>
    </Box>
  );
};

export default SensorsManagementAll;
