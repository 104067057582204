import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import AdminHesgarino from "./AdminHesgarino";
import AdminDataHesgarino from "./AdminDataHesgarino";

const AdminHesgarinoIndex = () => {
  const lang = useSelector((state) => state.global.language);
  return (
    <Box
      width="98vw"
      height="83vh"
      display="flex"
      gap="1rem"
      m={lang === "Fa" ? "1rem 1rem 0 0" : "1rem 0 0 1rem"}
    >
      <AdminHesgarino />
      <AdminDataHesgarino />
    </Box>
  );
};

export default AdminHesgarinoIndex;
