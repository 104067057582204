import { Box, Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween/FlexBetween";
import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-plugin-annotation";
import annotationPlugin from "chartjs-plugin-annotation";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "jalali-moment";
import W1 from "../../assets/w1.png";
import { getDateShamsi, getDateShamsiAirSensors } from "Utils/Utils";
Chart.register(...registerables);
Chart.register(annotationPlugin);

const customizeTooltip = (tooltipModel) => {
  // Get the tooltip element
  let tooltipEl = document.getElementById("custom-tooltip");

  // Create the tooltip element if it doesn't exist
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "custom-tooltip";
    document.body.appendChild(tooltipEl);
  }

  // Hide the tooltip if there are no tooltips to display
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set tooltip content and position
  tooltipEl.innerHTML = tooltipModel.body[0].lines[0];
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left = `${tooltipModel.caretX}px`;
  tooltipEl.style.top = `${tooltipModel.caretY}px`;
  tooltipEl.style.pointerEvents = "none";
};

const LineNormal = ({
  th1 = true,
  th2,
  fillColor,
  sensorData,
  // min,
  // max,
  stepSize,
  thv1,
  thv2,
  h,
}) => {
  console.log("🚀 ~ file: LineNormal.jsx:52 ~ sensorData:", sensorData);
  const theme = useTheme();
  const [flag, setFlag] = useState(true);
  // const thresholdValue = 30;
  // const firstDate = useSelector((state) => state.sensors.date1);
  // const secondDate = useSelector((state) => state.sensors.date2);

  // function areDatesEqual(date1, date2) {
  //   return (
  //     date1.year === date2.year &&
  //     date1.month === date2.month &&
  //     date1.day === date2.day
  //   );
  // }

  // const [equal, setEqual] = useState(false);
  const dataLabel = [];
  const dataSensor = [];
  const chartBarData = [];
  // useEffect(() => {
  //   firstDate === secondDate ? setEqual(true) : setEqual(false);
  // }, [secondDate]);
  if (sensorData && sensorData.data.length > 0) {
    console.log("🚀 ~ file: LineNormal.jsx:76 ~ sensorData:", sensorData);
    // sensorData?.data.map((item) => {
    //   const date = new Date(item.ts);

    //   const persianDate = date.toLocaleDateString("fa-IR-u-ca-persian", {
    //     // year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //   });

    //   // const persianTime = date.toLocaleTimeString("fa-IR-u-ca-persian", {
    //   //   hour: "2-digit",
    //   //   minute: "2-digit",
    //   //   hour12: false,
    //   // });

    //   // dataLabel.push(
    //   //   areDatesEqual(firstDate, secondDate) ? persianDate : persianDate
    //   // );

    //   dataLabel.push(persianDate);
    //   dataSensor.push(item.value);

    //   chartBarData.push(50);
    // });
    sensorData?.data.forEach((item) => {
      const date = new Date(item.ts);
      const persianDate = date.toLocaleDateString("fa-IR-u-ca-persian", {
        month: "2-digit",
        day: "2-digit",
      });

      dataLabel.push(persianDate);
      dataSensor.push(item.value);
      chartBarData.push(50);
    });
  }
  console.log(
    "🚀 ~ file: LineNormal.jsx:105 ~ sensorData[0].data.map ~ dataLabel:",
    dataLabel
  );

  const dailyData = {};

  sensorData?.data.forEach((item) => {
    const timestamp = item.ts;
    const date = new Date(timestamp);
    const dayKey = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    if (!dailyData[dayKey]) {
      dailyData[dayKey] = { sum: 0, count: 0 };
    }

    dailyData[dayKey].sum += parseFloat(item.value); // Assuming "value" is a string that needs to be converted to a number
    dailyData[dayKey].count += 1;
  });
  console.log("🚀 ~ file: LineNormal.jsx:108 ~ dailyData:", dailyData);

  // Calculate the mean for each day
  const dailyMeans = {};

  for (const dayKey in dailyData) {
    const { sum, count } = dailyData[dayKey];
    dailyMeans[dayKey] = sum / count;
  }
  console.log("🚀 ~ file: LineNormal.jsx:127 ~ dailyMeans:", dailyMeans);

  const label = Object.keys(dailyMeans).reverse(); // Extract and reverse the keys
  console.log("🚀 ~ file: LineNormal.jsx:135 ~ label:", label);
  const shamsiLabel = label?.map((item) => getDateShamsiAirSensors(item));
  const dataChart = label?.map((label) => dailyMeans[label]);
  console.log("🚀 ~ file: LineNormal.jsx:135 ~ dataChart:", dataChart);
  const chartData = sensorData ? dataChart : [0, 0, 0, 0, 0, 0, 0];
  const min = Math.round(Math.min(...chartData) * 0.8);
  const max = Math.round(Math.max(...chartData) * 1.2);
  const dataLabels = sensorData ? shamsiLabel : [1, 2, 3, 4, 5, 6, 7];
  console.log("🚀 ~ file: LineNormal.jsx:142 ~ dataLabels:", dataLabels);
  // const backgroundcolor = [];
  // for (let i = 0; i < chartData.length; i++) {
  //   if (chartData[i] < thv1) {
  //     backgroundcolor.push("rgba(47,128,237,0.3)");
  //   }
  //   if (chartData[i] >= thv1) {
  //     backgroundcolor.push("rgba(252,73,73,0.3)");
  //   }
  // }
  const skipped = (ctx, value) =>
    ctx.p0.skip || ctx.p1.skip ? value : undefined;
  const down = (ctx, value) =>
    ctx.p1.parsed.y > thv1 || ctx.p0.parsed.y > thv1 ? value : undefined;
  const data = {
    labels: dataLabels,
    datasets: [
      //   {
      //     type: "bar",
      //     label: "First dataset",
      //     data: chartBarData,
      //     barThickness: 25,
      //     backgroundColor: "rgba(42,128,244,0.1)",
      //     options: {
      //       tooltips: { enabled: false },
      //     },
      //   },
      {
        lineTension: 0,
        label: " ",
        data: chartData,
        showLine: true,
        fill: true,
        //  {
        //   target: {
        //     value: 30,
        //   },
        //   above: "red",
        //   below: "green",
        // },
        backgroundColor: (context) => {
          const bgColor = [
            "rgba(54,162,256,0.5)",
            "rgba(54,162,256,0.3)",
            "rgba(54,162,256,0.1)",
          ];
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, bgColor[0]);
          gradientBg.addColorStop(0.5, bgColor[1]);
          gradientBg.addColorStop(1, bgColor[2]);

          return gradientBg;
        },
        borderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];
          console.log("val", value);
          return value > thv1 ? "blue" : theme.palette.others.first;
        },
        segment: {
          backgroundColor: (ctx) =>
            skipped(ctx, fillColor ? fillColor : "rgba(252,73,73,0.3") ||
            down(ctx, fillColor ? fillColor : "rgba(252,73,73,0.3"),
          borderColor: (ctx) =>
            skipped(ctx, fillColor ? fillColor : theme.palette.others.second) ||
            down(ctx, fillColor ? fillColor : theme.palette.others.second),
          // borderDash: (ctx) => skipped(ctx, [6, 6]),
          // fill: {
          //   target: {
          //     value: 30,
          //   },
          //   above: "red",
          //   below: "green",
          // },
        },
        spanGaps: true,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: function (context) {
          var index = context.dataIndex;
          var value = context.dataset.data[index];

          return value > thv1 ? "rgba(255,0,0,0.3)" : "green";
        },
        pointBackgroundColor: "red",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  };
  return (
    <Box
      mt="1.5rem"
      sx={{
        opacity: sensorData ? 1 : 0.2,
        width: "100%",
        height: h ? h : "160px",
      }}
    >
      {/* /////////////////// */}
      {flag ? (
        <Line
          data={data}
          options={{
            tooltips: {
              enabled: false, // Disable default tooltips
              custom: customizeTooltip, // Use the custom tooltip function
            },
            elements: {
              point: {
                radius: 0,
              },
            },

            plugins: {
              tooltip: {
                usePointStyle: true,
                callbacks: {
                  labelPointStyle: function (context) {
                    return {
                      pointStyle: "star",
                      rotation: 0,
                    };
                  },
                },
                callbacks: {
                  title: (xDatapoint) => {
                    return xDatapoint.raw;
                  },
                  label: (yDatapoint) => {
                    return yDatapoint.raw;
                  },
                  // afterLabel: function(tooltipItem, data) {

                  //   var percent = Math.round((chartData[tooltipItem['index']] ))
                  //   return '(' + percent + '%)';
                  // },
                  backgroundColor: "#FFF",
                  titleFontSize: 16,
                  titleFontColor: "#0066ff",
                  bodyFontColor: "#000",
                  bodyFontSize: 14,
                  displayColors: false,
                },
              },
              // tooltips: {

              //   mode: "nearest",
              //   intersect: true,
              //   backgroundColor: "rgba(255,255,255,0.8)",
              //   titleFontColor: "#000",
              //   titleFontSize: 16,
              //   bodyFontColor: "#000",
              //   bodyFontSize: 14,
              // },
              annotation: {
                annotations: {
                  line1: th1
                    ? {
                        type: "line",
                        yMin: thv1,
                        yMax: thv1,
                        borderColor: theme.palette.others.second,
                        borderWidth: 1,
                      }
                    : null,
                  line2: th2
                    ? {
                        type: "line",
                        yMin: thv2,
                        yMax: thv2,
                        borderColor: theme.palette.others.second,
                        borderWidth: 1,
                      }
                    : null,
                },
              },
              legend: {
                display: false,
              },
            },
            maintainAspectRatio: false,

            scales: {
              y: {
                min: min,
                max: max,
                title: {
                  display: false,
                  text: "",
                },

                grid: {
                  color: theme.palette.background.second,
                  borderDash: [3, 3],
                },
                ticks: {
                  // stepSize: stepSize,
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              x: {
                title: {
                  display: false,
                  text: "",
                },
                grid: {
                  borderDash: [3, 3],
                  color: theme.palette.background.second,
                },
                ticks: {
                  display: true,
                  color: theme.palette.fontColor.main,
                },
              },
              yAxes: [
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],

              xAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    suggestedMin: 0,
                  },
                },
                {
                  gridLines: {
                    drawBorder: false,
                  },
                },
              ],
            },
          }}
        />
      ) : null}
    </Box>
  );
};

export default LineNormal;
