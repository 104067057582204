import L from "leaflet";
import "leaflet/dist/leaflet.css";
import RR1Icon1 from "../../assets/Sensor Icon/R-R 1.png";
import RR2Icon1 from "../../assets/Sensor Icon/R-R 2.png";
import GG1Icon1 from "../../assets/Sensor Icon/G-G 1.png";
import GG2Icon1 from "../../assets/Sensor Icon/G-G 2.png";
import GY1Icon1 from "../../assets/Sensor Icon/G-Y 1.png";
import GY2Icon1 from "../../assets/Sensor Icon/G-Y 2.png";
import RG1Icon1 from "../../assets/Sensor Icon/R-G 1.png";
import RG2Icon1 from "../../assets/Sensor Icon/R-G 2.png";
import RY1Icon1 from "../../assets/Sensor Icon/R-Y 1.png";
import RY2Icon1 from "../../assets/Sensor Icon/R-Y 2.png";
import YY1Icon1 from "../../assets/Sensor Icon/Y-Y 1.png";
import YY2Icon1 from "../../assets/Sensor Icon/Y-Y 2.png";
var RR1Icon = L.icon({
    iconUrl: RR1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });
  var RR2Icon = L.icon({
    iconUrl: RR2Icon1,
    iconSize: [60, 62],
    iconAnchor: [10, 62],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });

  var GG1Icon = L.icon({
    iconUrl: GG1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });

  var GG2Icon = L.icon({
    iconUrl: GG2Icon1,
    iconSize: [60, 62],
    iconAnchor: [10, 62],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });

  var GY1Icon = L.icon({
    iconUrl: GY1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });
  var GY2Icon = L.icon({
    iconUrl: GY2Icon1,
    iconSize: [60, 62],
    iconAnchor: [10, 62],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });
  var RG1Icon = L.icon({
    iconUrl: RG1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
    className: 'marker-transition' 

  });
  var RG2Icon = L.icon({
    iconUrl: RG2Icon1,
    iconSize: [60, 62],
    iconAnchor: [10, 62],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });

  var RY1Icon = L.icon({
    iconUrl: RY1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });
  var RY2Icon = L.icon({
    iconUrl: RY2Icon1,
    iconSize: [60, 62],
    iconAnchor: [10, 62],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });
  var YY1Icon = L.icon({
    iconUrl: YY1Icon1,
    iconSize: [52, 54],
    iconAnchor: [10, 54],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });
  var YY2Icon = L.icon({
    iconUrl: YY2Icon1,
    iconSize: [60, 62],
    iconAnchor: [10, 62],
    popupAnchor: [2, -10],
    className: 'marker-transition' 
  });
export const getIcon = (item, selectedIcon) => {
    console.log("🚀 ~ file: GetIcon.js:105 ~ getIcon ~ item:", item)
    console.log(
      "🚀 ~ file: SensorsManagementAll.jsx:260 ~ getIcon ~ selectedIcon:",
      selectedIcon
    );

    const data = item.lastData;
    let statusHumidity = null;
    let statusTemp = null;
    const humidityThreshold1 = 30;
    const humidityThreshold2 = 60;
    const temperatureThreshold1 = 10;
    const temperatureThreshold2 = 30;

    const lastHumidity = data.HA?.[data.HA.length - 1];
    console.log("🚀 ~ file: GetIcon.js:119 ~ getIcon ~ lastHumidity:", lastHumidity)

    const lastTemperature = data.TA?.[data.TA.length - 1];

    if (lastHumidity && lastHumidity.value) {
      if (
        lastHumidity.value > humidityThreshold1 &&
        lastHumidity.value < humidityThreshold2
      ) {
        statusHumidity = 0;
      } else if (lastHumidity.value < humidityThreshold1) {
        statusHumidity = -1;
      } else if (lastHumidity.value > humidityThreshold2) {
        statusHumidity = 1;
      }
    }

    if (lastTemperature && lastTemperature.v) {
      if (
        lastTemperature.v > temperatureThreshold1 &&
        lastTemperature.v < temperatureThreshold2
      ) {
        statusTemp = 0;
      } else if (lastTemperature.v < temperatureThreshold1) {
        statusTemp = -1;
      } else if (lastTemperature.v > temperatureThreshold2) {
        statusTemp = 1;
      }
    }

    if (statusHumidity === 0 && statusTemp === 0 && item.id === selectedIcon)
      return GG2Icon;
    if (statusHumidity === 0 && statusTemp === 0 && item.id !== selectedIcon)
      return GG1Icon;
    if (statusHumidity === 0 && statusTemp === 1 && item.id === selectedIcon)
      return RG2Icon;
    if (statusHumidity === 0 && statusTemp === 1 && item.id !== selectedIcon)
      return RG1Icon;
    if (statusHumidity === 1 && statusTemp === 0 && item.id === selectedIcon)
      return GY2Icon;
    if (statusHumidity === 1 && statusTemp === 0 && item.id !== selectedIcon)
      return GY1Icon;
    if (statusHumidity === 1 && statusTemp === 1 && item.id === selectedIcon)
      return RY2Icon;
    if (statusHumidity === 1 && statusTemp === 1 && item.id !== selectedIcon)
      return RY1Icon;

    if (statusHumidity === -1 && statusTemp === -1 && item.id === selectedIcon)
      return RR2Icon;
    if (statusHumidity === -1 && statusTemp === -1 && item.id !== selectedIcon)
      return RR1Icon;
    if (statusHumidity === -1 && statusTemp === 0 && item.id === selectedIcon)
      return RG2Icon;
    if (statusHumidity === -1 && statusTemp === 0 && item.id !== selectedIcon)
      return RG1Icon;
    if (statusHumidity === 0 && statusTemp === -1 && item.id === selectedIcon)
      return GY2Icon;
    if (statusHumidity === 0 && statusTemp === -1 && item.id !== selectedIcon)
      return GY1Icon;

    if (statusHumidity === 1 && statusTemp === -1 && item.id === selectedIcon)
      return YY2Icon;
    if (statusHumidity === 1 && statusTemp === -1 && item.id !== selectedIcon)
      return YY1Icon;
    if (statusHumidity === -1 && statusTemp === 1 && item.id === selectedIcon)
      return RR2Icon;
    if (statusHumidity === -1 && statusTemp === 1 && item.id !== selectedIcon)
      return RR1Icon;

    if (item.id === selectedIcon) return GG2Icon;
    else return GG1Icon;
  };