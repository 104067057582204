import { Box, useTheme } from "@mui/material";
import BoxSensorsManagement from "components/BoxSensorsManagement/BoxSensorsManagement";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getSensorinoData } from "Services/Http/http.service";
import BoxSensorinoCard from "./BoxSensorinoCard";

const SensorinoCards = ({ selectedIcon, toggle }) => {
  const convertedData = [];

  const theme = useTheme();
  const [animationKey, setAnimationKey] = useState(0);
  const token = useSelector((state) => state.global.token);

  const allSensorinosQuery = useQuery(
    ["allSensorinos", token, selectedIcon],
    () => {
      return getSensorinoData(token, selectedIcon);
    }
  );
  const { data, isLoading, isSuccess } = allSensorinosQuery;
  console.log("🚀 ~ SensorinoCards ~ data:", data);
  const sensorinoData = data?.data?.sensorinoData[0] || {};
  console.log("🚀 ~ SensorinoCards ~ sensorinoData:", sensorinoData);
  const newData = [
    { name: "سطح انرژی (سرزندگی)", value: sensorinoData?.energyLevel },
    {
      name: "آشفتگی انرژی (میزان استرس)",
      value: sensorinoData?.energyDisturbance,
    },
    {
      name: "اختلال در جذب نهاده",
      value: sensorinoData?.nutrientAbsorptionDisorder,
    },
    { name: "بار / فشار ویروسی", value: sensorinoData?.viralLoad },
    { name: "بار / فشار باکتریایی", value: sensorinoData?.bacterialLoad },

    { name: "بار / فشار قارچی", value: sensorinoData?.fungalLoad },

    { name: "تغییرات", value: sensorinoData?.changes },
  ];
  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
  }, [selectedIcon]);

  return (
    isSuccess && (
      <Box
        sx={{
          position: "absolute",
          top: "27rem",
          zIndex: "1000",
          width: "99%",
          height: "163px",
          p: "0 2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.5rem",
        }}
      >
        {newData
          ? newData.map((item, index) => {
              return (
                <motion.div
                  key={`${item.name}-${animationKey}`}
                  initial={{ scale: 0.7 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.25 * (index + 1) }}
                >
                  <BoxSensorinoCard
                    type={item.name}
                    data={item.value}
                    color={theme.palette.others.third}
                    // toggle={toggle}
                  />
                </motion.div>
              );
            })
          : null}
      </Box>
    )
  );
};

export default SensorinoCards;
