import {
  FormControl,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useState } from "react";

import rtlPlugin from "stylis-plugin-rtl";

import { prefixer } from "stylis";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { MOBILE_WIDTH } from "constants/constant";
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const SelectDevice = ({ selectedDevice, setSelectedDevice, data3 }) => {
  const handleChange = (event) => {
    setSelectedDevice(event.target.value);
  };
  const isMobileScreens = useMediaQuery(`(max-width:${MOBILE_WIDTH}px)`);

  return (
    <CacheProvider value={cacheRtl}>
      <FormControl sx={{ m: 0 }}>
        <Select
          value={selectedDevice}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            // direction: "rtl",
            borderRadius: "8px",
            width: isMobileScreens ? "42vw" : "160px",
            height: "30px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#40405e",
            },
          }}
        >
          {data3.map((item, index) => (
            <MenuItem value={item} key={index} sx={{ fontSize: "12px" }}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CacheProvider>
  );
};

export default SelectDevice;
